import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { LoaderService } from '../services/loader.service';

@Injectable()
export class LoaderInterceptor implements HttpInterceptor {
  private totalRequests: number = 0;
  // Define the list of excluded URLs here
  private excludedUrls = ['emailAlreadyRegistered', 'checkUsernameValidity'];

  constructor(private loadingService: LoaderService) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    
    
    let isExcluded : boolean = false;

    if (request.method == 'POST')
    {
      let bodyData: any = request.body;
      
      let functionName: any = bodyData['functionName'] != undefined ? bodyData['functionName'] : '';
      
      // Check if the functionName is in the excluded URLs list
      isExcluded = this.excludedUrls.some(url => functionName.includes(url));
    }
    
    // If request is not excluded, set loading to true
    if (!isExcluded) {
      this.totalRequests++;
    
      this.loadingService.setLoading(true);
    }
    return next.handle(request).pipe(
      finalize(() => {
        this.totalRequests--;
        if (this.totalRequests <= 0) {
          this.loadingService.setLoading(false);
        }
      })
    );
  }
  
}
