import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { GameService } from '@gamelist/services/api/game.service';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SharedService {
  
    constructor(public gameService: GameService) {}
    ngOnInit() {

        // set cookie data
        this.checkLoggedinUserData();
    }

    checkLoggedinUserData()
    {
        // Split the document.cookie string by ";"
        let cookiesArray: string[] = document.cookie.split(";");

        // Converting object array like {apiType: 'pull', PHPSESSID: '21qkaqo2omcu3c6p474pk20t66', …}
        let cookieObject: any = {};
        let cookieData: any = [];
        let userId: any = null;
        let subscription_level: number = 0;
        let isUserLoggedIn: boolean = false;

        cookiesArray.forEach(cookie => {
            let cookieData = cookie.trim().split("=");
            
            cookieObject[cookieData[0]] = cookieData[1];
        });

        // get cookie data
        cookieData = cookieObject;
        
        // set isUserLoggedIn & user id
        if(cookieData.userid) {
            userId = cookieData.userid;
            // On temp base setting subscription_level 2 static for production as client want to activate create real-time game for all
            if (environment.production)
            {
                subscription_level = 2;
            } else {
                subscription_level = cookieData.subscription_level;
            }
            
            isUserLoggedIn = true;
        }
        
        return {isUserLoggedIn:isUserLoggedIn, userId: userId, subscription_level:subscription_level}
    }

    private listSubject: BehaviorSubject<string[]> = new BehaviorSubject<string[]>([]);
    list$: Observable<string[]> = this.listSubject.asObservable();

    updateList(newList): void {
        this.listSubject.next(newList);
    }
}
