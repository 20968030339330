import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { environment } from '@env/environment';
import { GameFacadeService } from '@game/facades/game-facade.service';
import { Observable, interval } from 'rxjs';
import { takeWhile } from 'rxjs/operators';
import { SharedService } from 'src/app/services/shared.service';

@Component({
    selector: 'app-game-list-entry',
    templateUrl: './game-list-entry.component.html',
    styleUrls: ['./game-list-entry.component.scss']
})
export class GameListEntryComponent implements OnInit {

    turnTimeLeft$: Observable<number>;

    _game;
    turnTime$: Observable<number>;
    userId: any;
    @Input()
    set game(game) {
        this._game = game;
        if (game.options.teams !== 'Singles') {
            this._game.teams = this._game.teams.map(team => {
                team.players = team.players.map(playerId => game.players.find(player => player.id === playerId))
                return team;
            });
        }
        this._game.players.forEach(player => {
            switch (player.level) {
                case 'grunt':
                    if (player.missedTurn >= 2) {
                        player.state = 'awol';
                    }
                    break;
                case 'strategist':
                    if (player.missedTurn >= 3) {
                        player.state = 'awol';
                    }
                    break;
                case 'commander':
                case 'centcom':
                    if (player.missedTurn >= 4) {
                        player.state = 'awol';
                    }
                    break;
                default:
                    // Default action when none of the cases match
                    break;
            }
        });
        console.log(this._game.players);
    }
    @Input() opened;
    @Output() viewGame = new EventEmitter();
    @Output() joinGame = new EventEmitter();
    @Output() showTeamSelection = new EventEmitter();
    @Output() leaveGame = new EventEmitter();

    constructor(private gameFacadeService: GameFacadeService,private sharedService: SharedService) {
        let isLoggedInData = this.sharedService.checkLoggedinUserData();
        
        this.userId = isLoggedInData.userId;
console.log(this.userId);
     }

    countdown: number = 0; // Set the initial countdown value
    timer$; // An observable to manage the timer
    counting: boolean = false; // Indicates whether the countdown is active
    ngOnInit() {
       
        // turn time left
        if ( this._game.state === 'running' &&  this._game.turn != undefined)
        {
            this.countdown = this._game.turn.timeLeft/1000;

            // Initialize the timer observable
            this.turnTimeLeft$ = interval(1000).pipe(
                takeWhile(() => this.counting && this.countdown > 0)
            );
            
            this.startCountdown();
        }
        
    }

    startCountdown() {
        
        this.counting = true;
        this.turnTimeLeft$.subscribe(() => {
            if (this.countdown > 0) {
            this.countdown--;
            
            } else {
            this.counting = false;
            }
        });
    }

    public getMapImageUrl(map) {
        return `${environment.mapsUrl}/${map.key}/${map.version}/${map.key}.jpg`;
    }

    public getGameLink(game) {
        return `${environment.websiteUrl}/beta/${game.id}`;
    }

    public getTurnLengthRepresentation(length: number) {
        if (length === 86400) {
            return '24 hours';
        } else {
            return (length / 60) + ' minutes';
        }
    }

    public getButtonAction(game) {
        // join, leave, view, victory, defeated, turn, team turn, view, ended, cancelled
        if (game.state === 'cancelled') {
            return 'cancelled';
        }
        switch (game.playerState) {
            case 'waiting':
                return 'leave';
            case 'active':
                return this.checkPlayerState(game)
            case 'not_player':
                switch (game.state) {
                    case 'waiting':
                        return 'join';
                    case 'running':
                    case 'paused':
                        return 'view';
                    default:
                        return game.state;
                }
            default:
                return game.playerState;
        }
    }

    public checkPlayerState(game) {
        const currentPlayerId = this.userId;
        console.log('currentPlayerId', currentPlayerId);
        const currentPlayer = game.players.find(player => player.id == currentPlayerId);
    console.log('CurrentPlayerData', currentPlayer);
        if (currentPlayer && currentPlayer.state == 'awol') {
            return currentPlayer.state;
        } else {
            // Handle the case when the current player is not found in the list
            return 'view';
        }
    }
    
}
