import { Component, OnInit, Renderer2 } from '@angular/core';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  slideIndex: number = 0;
  totalSlides: number;

  constructor(private renderer: Renderer2) {
    this.totalSlides = 7; // Update this to the actual number of unique slides you have
  }

  ngOnInit(): void {
    this.showSlides();
  }

  showSlides() {
    const slidesContainer = document.querySelector('.slides') as HTMLElement;

    // Apply sliding transition
    this.renderer.setStyle(slidesContainer, 'transition', 'transform 0.5s ease-in-out');
    this.renderer.setStyle(slidesContainer, 'transform', `translateX(-${this.slideIndex * 100}%)`);

    this.slideIndex++;

    // Reset to first slide seamlessly after showing the duplicate
    if (this.slideIndex > this.totalSlides) {
      setTimeout(() => {
        this.renderer.setStyle(slidesContainer, 'transition', 'none');
        this.renderer.setStyle(slidesContainer, 'transform', 'translateX(0)');
        this.slideIndex = 1; // Move to the second slide (original first)
      }, 500); // Match this duration to the transition time
    }

    setTimeout(() => this.showSlides(), 6000); // Change image every 6 seconds
  }
}
