import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { Router, NavigationExtras } from '@angular/router';
import { environment } from '@env/environment';
import { GameService } from '@gamelist/services/api/game.service';
import { ModalService } from '@core/services/modal.service';

@Component({
    selector: 'app-game-list',
    templateUrl: './game-list.component.html',
    styleUrls: ['./game-list.component.scss']
})
export class GameListComponent implements OnInit {

    @Input() games;
    @Input() status:string = '';
    selectedGame = null;
    teamGameToJoin = null;
    // Inside your component, when navigating to /beta
    navigationExtras: NavigationExtras = {
        skipLocationChange: true
    };

    constructor(
        private gameService: GameService, 
        private modalService: ModalService,
        private router: Router,
        private changeDetectorRef: ChangeDetectorRef
        ) {
    }

    ngOnInit() {
        
    }

    public toggleDetails(gameId: number) {
        this.selectedGame = this.selectedGame === gameId ? null : gameId;
    }

    public viewGame(game) {
        window.top.location.href = `${environment.websiteUrl}/beta/${game.id}`;
    }

    public joinGame(game) {
        this.gameService.joinGame(game.id)
            .subscribe((response) => {
                if (response.result.ok) {
                    this.games = this.games.map(_game => {
                        return game.id === _game.id ? response.list : _game;
                    });
                }
            })
        ;
    }

    public joinTeamGame(game, teamId) {
        this.gameService.joinGame(game.id, teamId)
            .subscribe((response) => {
                if (response.result.ok) {
                    this.modalService.close('team-selection');
                    this.games = this.games.map(_game => {
                        return game.id === _game.id ? response.list : _game;
                    });
                }
            })
        ;
    }

    public leaveGame(game) {
        this.gameService.leaveGame(game.id)
            .subscribe((response) => {
                if (response.result.ok) {
                    this.games = this.games.map(_game => {
                        return game.id === _game.id ? response.list : _game;
                    });
                }
            })
        ;
    }

    public showTeamSelection(game) {
        this.teamGameToJoin = game;
        this.modalService.open('team-selection');
    }
}
