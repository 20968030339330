import { Injectable, OnDestroy } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import {
    AdvanceTroopsAction,
    AttackAction,
    BeginTurnAction,
    BlitzAction,
    CallUpReservesAction,
    ClearAttacksAction,
    DeployTroopsAction,
    EndAssaultAction,
    EndReinforcementAction,
    GetCurrentGameAction,
    GetGamePreferencesAction,
    GetGameRecordAction,
    GetReinforceableRegionsAction,
    JoinAction,
    JoinTeamAction,
    LeaveAction,
    ReinforceRegionAction,
    ReturnToBaseAction,
    SendChatMessageAction,
    SetPlayerColorAction,
    SetPlayerLastReadChatAction,
    SkipDeploymentAction,
    SkipReserveCallUpAction
} from '@game/store/actions/actions';
import { WebsocketService } from '@core/services/websocket.service';
import { environment } from '@env/environment';
import { BehaviorSubject, combineLatest, interval, merge, Observable, Subject, of } from 'rxjs';
import { MessageHandlerService } from '@game/services/message-handler.service';
import {
    debounceTime,
    distinctUntilChanged,
    filter,
    first,
    map,
    pairwise,
    shareReplay,
    startWith,
    switchMap,
    takeUntil,
    takeWhile,
    tap,
    withLatestFrom
} from 'rxjs/operators';
import { StoreGameState } from '@game/store/states/game.state';
import {
    AttackInfo,
    GameOptions,
    GamePlayer,
    GameRegion,
    GameRound,
    GameStatus,
    GameTeam,
    PlayerStatus,
    Reserve
} from '@game/store/models/game.model';
import { hasElements, notNull } from '@core/utils';
import { StatusMessageResolverService } from '@game/util/status-message-resolver.service';
import { ActionButtonResolverService, ActionButtonState } from '@game/util/action-button-resolver.service';
import { GamePlayerIntel } from '@game/interfaces/game-player-intel';
import { ChatMessage } from '@game/interfaces/chat-message';
import { tag } from 'rxjs-spy/operators';
import { ProgressService, ProgressStep } from '@game/services/progress.service';
import { MapData, MapInfo, MapRegion, UIMap } from '@game/models/ui-map';
import { MapState } from '@game/models/map-state';
import { MapLoaderService } from '@game/services/map-loader.service';
import { ToastrService } from 'ngx-toastr';

const ONE_SECOND = 1000;
const CONTAINER_EMPTY_IMG = 'data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7';
const UPDATE_READ_MESSAGE_DEBOUNCE_TIME = 3000;

@Injectable({
    providedIn: 'root'
})
export class GameFacadeService implements OnDestroy {

    private connectionOpenObserver$: Subject<any>;
    private connectionCloseObserver$: Subject<any>;
    private onDestroy$ = new Subject<boolean>();

    @Select(StoreGameState.players)
    private readonly players$: Observable<GamePlayer[]>;

    @Select(StoreGameState.round)
    private readonly _round$: Observable<GameRound>;

    @Select(StoreGameState.status)
    private readonly _status$: Observable<GameStatus>;

    @Select(StoreGameState.playerId)
    private readonly playerId$: Observable<number>;

    @Select(StoreGameState.troopsOnCallUp)
    private readonly troopsOnCallUp$: Observable<number>;

    @Select(StoreGameState.regions)
    readonly regions$: Observable<GameRegion[]>;

    @Select(StoreGameState.record)
    private readonly record$: Observable<any[]>;

    @Select(StoreGameState.gameOptions)
    private readonly gameOptions$: Observable<GameOptions>;

    @Select(StoreGameState.playersColors)
    private readonly _playerColors$: Observable<any>;

    @Select(StoreGameState.mapKey)
    private readonly mapKey$: Observable<string>;

    @Select(StoreGameState.attacks)
    private readonly attacks$: Observable<AttackInfo[]>;

    @Select(StoreGameState.fromRegion)
    private readonly fromRegionId$: Observable<string>;

    @Select(StoreGameState.toRegion)
    private readonly toRegionId$: Observable<string>;

    @Select(StoreGameState.isCallUpMandatory)
    private readonly isReserveCallUpMandatory$: Observable<boolean>;

    @Select(StoreGameState.reinforceableRegions)
    private readonly reinforceableRegions$: Observable<any>;

    @Select(StoreGameState.lastReadMessageTime)
    private readonly lastReadMessageTime$: Observable<number>;

    @Select(StoreGameState.serverTime)
    private readonly lastServerTime$: Observable<number>;

    @Select(StoreGameState.target)
    private readonly targetPlayer$: Observable<number>;

    @Select(StoreGameState.lastDefeatedPlayer)
    private readonly lastDefeatedPlayer$: Observable<GamePlayer>;

    @Select(StoreGameState.turnsTaken)
    private readonly turnsTaken$: Observable<number>;

    @Select(StoreGameState.timeTaken)
    private readonly timeTaken$: Observable<number>;

    @Select(StoreGameState.pointsAwarded)
    private readonly pointsAwarded$: Observable<any>;

    @Select(StoreGameState.winnerPlayers)
    private readonly winnerPlayers$: Observable<GamePlayer>;

    @Select(StoreGameState.teams)
    private readonly teams$: Observable<GameTeam[]>;

    @Select(StoreGameState.cancelledMessage)
    private readonly cancelledMessage$: Observable<string>;

    readonly status$: Observable<GameStatus>;
    readonly playersTurn$: Observable<boolean>;
    private readonly playerColors$: Observable<any>;
    private readonly round$: Observable<GameRound>;
    private readonly remainingTime$: Observable<number>;
    
    private readonly currentPlayer$: Observable<GamePlayer>;
    private readonly selfPlayer$: Observable<GamePlayer>;
    private readonly fromRegion$: Observable<MapRegion>;
    private readonly toRegion$: Observable<MapRegion>;
    private readonly playerReserves$: Observable<Reserve[]>;
    private readonly playersIntel$: Observable<GamePlayerIntel[]>;
    private readonly chatMessages$: Observable<ChatMessage[]>;
    private readonly reserveCallUpPopupVisible$: Observable<boolean>;
    private readonly deployPopupVisible$: Observable<boolean>;
    private readonly assaultPopupVisible$: Observable<boolean>;
    private readonly reinforcePopupVisible$: Observable<boolean>;
    private readonly victoryPopupVisible$: Observable<boolean>;
    private readonly pausedPopupVisible$: Observable<boolean>;
    private readonly cancelledPopupVisible$: Observable<boolean>;
    private readonly troopsDue$: Observable<number>;
    private readonly mapInfo$: Observable<MapInfo>;
    private readonly regionsCanvasObjects$: Observable<any>; // TODO: typing
    private readonly titlesCanvasObjects$: Observable<any>; // TODO: typing
    private readonly containersCanvasObjects$: Observable<any>; // TODO: typing
    private readonly mapState$: Observable<MapState>;
    private readonly mapRepresentation$: Observable<UIMap>;
    private readonly containersImages$: Observable<any>;
    private readonly unreadMessages$: Observable<any[]>;
    private readonly lastMessageTime$: Observable<number>;
    private readonly isDeferredDeployment$: Observable<boolean>;

    private readonly debriefPopupVisible$ = new BehaviorSubject<boolean>(true);
    private readonly chatterOpen$ = new BehaviorSubject<boolean>(false);

    private readonly mapFromRegionId$ = new Subject();
    private readonly mapToRegionId$ = new Subject();

    private readonly teamSize$: Observable<number>;

    constructor(private store: Store,
                private progressService: ProgressService,
                private socketService: WebsocketService,
                private messageHandler: MessageHandlerService,
                private actionButtonResolver: ActionButtonResolverService,
                private statusMessageResolver: StatusMessageResolverService,
                private mapLoader: MapLoaderService,
                private toastr: ToastrService) {

        // subjects that will observe websocket service
        this.connectionOpenObserver$ = new Subject();
        this.connectionCloseObserver$ = new Subject();

        this.playerColors$ = this._playerColors$.pipe(
            // we need this check here because when player colors are updated a lot of UI elements are re-rendered because
            // they contain the player color. When we set a "last read message" a "preferences updated" is called, an in return
            // a new player prefereces object is returned tho the player colors object is updated everytime a set last read message
            // is sent
            // TODO: discuss the possibility of the game preferences update only return what was changed and if that would solve this or not
            distinctUntilChanged((a, b) => JSON.stringify(a) === JSON.stringify(b))
        );

        // round
        this.round$ = this._round$.pipe(
            filter(notNull),
            tag('round')
        );

        // status
        this.status$ = this._status$.pipe(
            filter(notNull),
            tap(val => {
                if (val === GameStatus.WaitingForPlayers) {
                    // if game is not yet started there will be no map representation, so we should
                    // send information to progress service here
                    this.progressService.stepLoaded(ProgressStep.MapRepresentation);
                }
            }),
            tag('status')
        );

        // Inside a method or constructor where you want to log the values
        this.lastServerTime$.subscribe((value) => {
            // console.log('Last Server Time:', value);
        });
        this.remainingTime$ = this.lastServerTime$.pipe(
            switchMap(
              () => interval(ONE_SECOND),
              (timestamp, i) => timestamp + (i * ONE_SECOND)
            ),
            withLatestFrom(
              this.round$.pipe(
                filter(notNull),
                map(round => round.turn.endsIn)
              ),
              this.status$.pipe(
                distinctUntilChanged(),
                map(status => !(status === GameStatus.Paused || status === GameStatus.Cancelled || status === GameStatus.Ended))
              )
            ),
            map(([now, endTime, running]) => running ? ((endTime - now) / ONE_SECOND) : null),
            takeWhile(val => val >= 0),
            tap((value) => {
            //   console.log('Remaining Time:', value); // Log the value to the console
            }),
            tag('remainingTime')
        );
        
        // is players turn?
        this.playersTurn$ = combineLatest(
            this.round$,
            this.playerId$.pipe(
                tag('playerId')
            )
        ).pipe(
            map(([round, playerId]: [GameRound, number]) => round.turn.player === playerId),
            tag('playersTurn')
        );

        const getUser = (players: GamePlayer[], playerId: number) => {
            const idx = players.findIndex(player => player.id === playerId);
            return idx > -1 ? players[idx] : null;
        };

        combineLatest(
            this.playersTurn$.pipe(
                distinctUntilChanged()
            ),
            this.status$.pipe(
                distinctUntilChanged()
            )
        ).pipe(
            takeUntil(this.onDestroy$)
        ).subscribe(([playersTurn, status]) => {
            if (playersTurn && status === GameStatus.Reinforcement) {
                // we just switched to reinforcement, get the reinforceable regions info
                this.store.dispatch(new GetReinforceableRegionsAction());
            }
        });

        // current player
        this.currentPlayer$ = combineLatest(
            this.players$.pipe(
                filter(notNull)
            ),
            this.round$
        ).pipe(
            map(([players, round]) => getUser(players, round.turn.player)),
            startWith(null),
            shareReplay(1),
            tag('currentPlayer')
        );

        // self player
        this.selfPlayer$ = combineLatest(
            this.players$.pipe(
                filter(notNull)
            ),
            this.playerId$
        ).pipe(
            map(([players, playerId]) => getUser(players, playerId)),
            shareReplay(1),
            tag('selfPlayer')
        );

        // map info
        this.mapInfo$ = this.mapKey$.pipe(
            filter(notNull),
            switchMap(mapName => this.mapLoader.loadMap(mapName)),
            tap(() => this.progressService.stepLoaded(ProgressStep.MapInfo)),
            shareReplay(1),
            tag('mapInfo')
        );

        // ui representation of the map
        this.mapRepresentation$ = combineLatest(
            this.regions$.pipe(
                filter(notNull),
                distinctUntilChanged(),
            ),
            this.playerColors$.pipe(
                filter(notNull),
            ),
            this.mapInfo$.pipe(
                map((mapInfo: MapInfo) => mapInfo.data),
            ),
            this.reinforceableRegions$.pipe(
                startWith({})
            ),
            this.players$.pipe(
                filter(notNull),
                map(players => players.reduce((acc, player) => {
                    acc[player.id] = player.team ? player.team : player.id;
                    return acc;
                }, {}))
            )
        ).pipe(
            tap(() => this.progressService.stepLoaded(ProgressStep.MapRepresentation)),
            map(([regions, playersColors, mapData, reinforceableRegions, teams]: [GameRegion[], any, MapData, any, any]) =>
                new UIMap(regions, playersColors, mapData.regions, reinforceableRegions, teams)
            ),
            shareReplay(1),
            tag('mapRepresentation')
        );

        // from region
        this.fromRegion$ = combineLatest(
            this.mapRepresentation$,
            merge(this.fromRegionId$, this.mapFromRegionId$)
        ).pipe(
            map(([uiMap, regionId]: [UIMap, string]) => {
                return regionId !== null ? uiMap.regions[regionId] : null;
            }),
            shareReplay(1),
            tag('fromRegion')
        );

        // to region
        this.toRegion$ = combineLatest(
            this.mapRepresentation$,
            merge(this.toRegionId$, this.mapToRegionId$)
        ).pipe(
            map(([uiMap, regionId]: [UIMap, string]) => {
                return regionId !== null ? uiMap.regions[regionId] : null;
            }),
            shareReplay(1),
            tag('toRegion')
        );

        // player reserves
        this.playerReserves$ = combineLatest(
            this.selfPlayer$.pipe(
                filter(notNull),
            ),
            this.mapRepresentation$
        ).pipe(
            map(([player, uiMap]: [GamePlayer, UIMap]) => {

                if (!player.reserves) {
                    return [];
                }

                return player.reserves.map(reserve => {
                    return {
                        ...reserve,
                        name: uiMap.regions[reserve.region].name,
                        owned: uiMap.regions[reserve.region].player === player.id
                    };
                });
            }),
            shareReplay(1),
            tag('playerReserves')
        );

        // players intel
        this.playersIntel$ = combineLatest(
            this.players$.pipe(
                filter(notNull)
            ),
            this.regions$
        ).pipe(
            map(([players, regions]: [GamePlayer[], GameRegion[]]) => {
                return players.map((player: GamePlayer) => {
                    return {
                        ...player,
                        regionsCount: player.regions.length,
                        troopsCount: regions === null ? 0 : player.regions.reduce((count, region) => {
                            return count + regions[region].troops;
                        }, 0)
                    };
                });
            }),
            tag('playersIntel')
        );

        let chatMessages = [];

        // chat messages
        this.chatMessages$ = this.record$.pipe(
            pairwise(),
            // debounceTime(500),
            map(recordDiff => {
                const newMessages = recordDiff[1]
                    .filter(i => recordDiff[0].indexOf(i) < 0)
                    .filter(record => record.kind === 'chat_updated' || record.kind === 'round_started')
                    .reduce((acc, entry) => {
                        if (entry.kind === 'round_started') {
                            acc.push({
                                type: 'round',
                                number: entry.round.number,
                                timestamp: entry.timestamp,
                                idx: `r.${entry.timestamp}`
                            });
                        } else if (entry.kind === 'chat_updated') {
                            acc.push(
                                ...entry.messages.map((msg, index) => {
                                    return {
                                        type: 'message',
                                        scope: msg.scope,
                                        from: msg.from,
                                        text: msg.text,
                                        timestamp: msg.timestamp,
                                        idx: `m.${entry.timestamp}.${index}`
                                    };
                                })
                            );
                        }
                        return acc;
                    }, [])
                ;

                chatMessages = [...chatMessages, ...newMessages].sort((r1, r2) => r2.timestamp - r1.timestamp);

                return chatMessages;
            }),
            distinctUntilChanged((a, b) => {
                if (a.length !== b.length) {
                    // different sizes so they are different
                    return false;
                }
                if (a.length === 0) {
                    // they are empty, so they are the same
                    return true;
                }
                // compare the idx of the last message
                return a[0].idx === b[0].idx;
            }),
            shareReplay(1),
            tag('chatMessages')
        );

        const filterMessages = (messages) => {
            return messages.filter(msg => msg.type === 'message');
        };

        this.lastMessageTime$ = this.chatMessages$.pipe(
            map(filterMessages),
            filter(hasElements),
            map(messages => messages[0].timestamp),
            tag('lastMessageTime')
        );

        this.unreadMessages$ = combineLatest(
            this.chatMessages$.pipe(
                map(filterMessages)
            ),
            this.lastReadMessageTime$,
            this.chatterOpen$
        ).pipe(
            debounceTime(100), // do not emit when multiple change at same time
            map(([messages, lastReadTime, chatterOpen]: [any[], number, boolean]) => {
                if (chatterOpen) {
                    return [];
                }
                return messages.filter(msg => msg.timestamp > lastReadTime);
            }),
            startWith([]),
            shareReplay(1),
            tag('unreadMessages')
        );

        // set last read message
        combineLatest(
            this.lastMessageTime$.pipe(
                withLatestFrom(this.lastReadMessageTime$),
                filter(([messageTime, lastReadMessageTime]) => messageTime > lastReadMessageTime),
                map(data => data[0])
            ),
            this.chatterOpen$
        ).pipe(
            filter(([_, chatOpen]) => chatOpen),
            debounceTime(UPDATE_READ_MESSAGE_DEBOUNCE_TIME)
        ).subscribe(([timestamp, _]) => {
            this.store.dispatch(new SetPlayerLastReadChatAction(timestamp));
        });

        // reserve callup popup
        this.reserveCallUpPopupVisible$ = combineLatest(
            this.status$,
            this.playersTurn$
        ).pipe(
            map(([status, playersTurn]: [GameStatus, boolean]) =>
                playersTurn && status === GameStatus.ReserveCallUp
            ),
            tag('reserveCallUpPopupVisible')
        );

        // deploy callup popup
        this.deployPopupVisible$ = combineLatest(
            this.status$,
            this.playersTurn$,
            this.fromRegion$
        ).pipe(
            map(([status, playersTurn, fromRegion]: [GameStatus, boolean, GameRegion]) =>
                playersTurn && fromRegion !== null && (status === GameStatus.Deployment || status === GameStatus.DeferredTroopsDeployment)
            ),
            tag('deployPopupVisible')
        );

        this.isDeferredDeployment$ = combineLatest(
            this.status$,
            this.playersTurn$
        ).pipe(
            map(([status, playersTurn]: [GameStatus, boolean]) =>
                playersTurn && status === GameStatus.DeferredTroopsDeployment
            ),
            tag('isDeferredDeployment')
        );

        // assault popup
        this.assaultPopupVisible$ = combineLatest(
            this.status$,
            this.playersTurn$,
            this.fromRegion$,
            this.toRegion$
        ).pipe(
            map(([status, playersTurn, fromRegion, toRegion]: [GameStatus, boolean, MapRegion, MapRegion]) =>
                playersTurn && fromRegion !== null && toRegion !== null && (status === GameStatus.Attack || status === GameStatus.Advance)
            ),
            tag('assaultPopupVisible')
        );

        // reinforce popup
        this.reinforcePopupVisible$ = combineLatest(
            this.status$,
            this.playersTurn$,
            this.fromRegion$,
            this.toRegion$
        ).pipe(
            map(([status, playersTurn, fromRegion, toRegion]: [GameStatus, boolean, MapRegion, MapRegion]) =>
                playersTurn && fromRegion !== null && toRegion !== null && status === GameStatus.Reinforcement
            ),
            tag('reinforcePopupVisible')
        );

        // victory popup
        this.victoryPopupVisible$ = combineLatest(
            this.status$,
            this.selfPlayer$.pipe(
                filter(notNull)
            ),
            this.winnerPlayers$.pipe(
                filter(notNull)
            )
        ).pipe(
            map(([status, player, winners]: [GameStatus, GamePlayer, number[]]) => {
                return status === GameStatus.Ended && winners && winners.includes(player.id);
            })
        );

        // paused popup
        this.pausedPopupVisible$ = this.status$.pipe(
            map(status => status === GameStatus.Paused)
        );

        // cancelled popup
        this.cancelledPopupVisible$ = this.status$.pipe(
            map(status => status === GameStatus.Cancelled)
        );

        // troops due
        this.troopsDue$ = combineLatest(
            this.status$,
            this.currentPlayer$
        ).pipe(
            map(([status, currentPlayer]: [GameStatus, GamePlayer]) => {
                if (status === GameStatus.Deployment) {
                    return currentPlayer.troopsDue;
                } else if (status === GameStatus.DeferredTroopsDeployment) {
                    return currentPlayer.deferredTroops;
                }
                return 0;
            }),
            shareReplay(1),
            tag('troopsDue')
        );

        // Map Related

        // map state
        this.mapState$ = combineLatest(
            this.status$,
            this.playersTurn$
        ).pipe(
            map(([status, playersTurn]: [GameStatus, boolean]) => {
                if (playersTurn) {
                    switch (status) {
                        case GameStatus.Deployment:
                        case GameStatus.DeferredTroopsDeployment:
                            return MapState.Deploy;

                        case GameStatus.Attack:
                            return MapState.Assault;

                        case GameStatus.Reinforcement:
                            return MapState.Reinforce;

                        case GameStatus.WaitingForPlayers:
                            return MapState.Disabled;
                    }
                }

                return MapState.Normal;
            }),
            tag('mapState')
        );

        // regions canvas objects
        this.regionsCanvasObjects$ = this.mapInfo$.pipe(
            switchMap((mapInfo: MapInfo) =>
                this.mapLoader.getRegionsCanvasObjects(mapInfo.assets.regions, mapInfo.data.regions)
            ),
            tap(() => this.progressService.stepLoaded(ProgressStep.RegionCanvasObjects)),
            shareReplay(1),
            tag('regionCanvasObjects'),
        );

        // titles canvas objects
        this.titlesCanvasObjects$ = this.mapInfo$.pipe(
            switchMap((mapInfo: MapInfo) =>
                this.mapLoader.getTitlesCanvasObjects(mapInfo.assets.titles, mapInfo.data.regions)
            ),
            tap(() => this.progressService.stepLoaded(ProgressStep.TitleCanvasObjects)),
            shareReplay(1),
            tag('titlesCanvasObjects'),
        );

        // containers canvas objects
        this.containersCanvasObjects$ = this.mapInfo$.pipe(
            switchMap((mapInfo: MapInfo) =>
                this.mapLoader.getContainersCanvasObjects(mapInfo.assets.containers, mapInfo.data.containers)
            ),
            tap(() => this.progressService.stepLoaded(ProgressStep.ContainerCanvasObjects)),
            shareReplay(1),
            tag('containersCanvasObjects')
        );

        // containers images
        this.containersImages$ = this.mapLoader.getContainersImages().pipe(
            shareReplay(1),
            tag('containersImages')
        );

        // team size
        this.teamSize$ = this.gameOptions$.pipe(
            filter(notNull),
            map((gameOptions: GameOptions) => {
                switch (gameOptions.teams) {
                    case 'Singles':
                        return 1;
                    case 'Doubles':
                        return 2;
                    case 'Triples':
                        return 3;
                    case 'Quads':
                        return 4;
                    case 'Fivers':
                        return 5;
                    case 'Sixers':
                        return 6;
                    case 'Crusade':
                        return 12;
                }
            })
        );
    }

    /* DISPATCH */

    /**
     * Loads the game
     */
    loadGame(gameId: number) {

        // connect socket
        // Todo: Websocket server connection
        this.socketService
            .connect(
                `${environment.serverUrl}/${gameId}/socket`,
                this.connectionOpenObserver$,
                this.connectionCloseObserver$
            )
            .subscribe(msg => {
                try {
                    this.messageHandler.handleMessage(msg);
                } catch (e) {
                    // this.toastr.error(e);
                }
            })
        ;

        // send command to get game
        this.store.dispatch(new GetCurrentGameAction());

        // send command to get game preferences
        this.store.dispatch(new GetGamePreferencesAction());

        // deffer execution to next javascript microtask so the map can be loaded first

        // send comment to get game record
        this.store.dispatch(new GetGameRecordAction());
    }

    /**
     * Player begin his turn
     */
    beginTurn() {
        this.store.dispatch(new BeginTurnAction());
    }

    /**
     * Player ends his turn
     */
    endTurn() {
        this.store.dispatch(new EndReinforcementAction());
    }

    /**
     * Deploy troops on a given region
     * @param region region where troops will be deployed
     * @param troops number of troops to be deployed
     */
    deployTroops(region: string, troops: number) {
        this.store.dispatch(new DeployTroopsAction(region, troops));
    }

    /**
     * Attacks a region
     * @param from region where to attack from
     * @param to   region where to attack to
     */
    attack(from: string, to: string) {
        this.store.dispatch(new AttackAction(from, to));
    }

    /**
     * Blitz a region
     * @param from region where to attack from
     * @param to   region where to attack to
     */
    blitz(from: string, to: string) {
        this.store.dispatch(new BlitzAction(from, to));
    }

    /**
     * Adavance troops to the conquered region
     * @param count number of troops to advance
     */
    advanceTroops(count: number) {
        this.store.dispatch(new AdvanceTroopsAction(count));
    }

    /**
     * Player ends the assault
     */
    endAssault() {
        this.store.dispatch(new EndAssaultAction());
    }

    /**
     * Reinforces a given region
     * @param from   region where from reinforce from
     * @param to     region where from reinforce to
     * @param troops number of troops
     */
    reinforceRegion(from: string, to: string, troops: number) {
        this.store.dispatch(new ReinforceRegionAction(from, to, troops));
    }

    /**
     * Player ends the reinforcement
     */
    endReinforcement() {
        this.store.dispatch(new EndReinforcementAction());
    }

    /**
     * Player skips the deferred deployment
     */
    skipDeployment() {
        this.store.dispatch(new SkipDeploymentAction());
    }

    /**
     * Call up reserves
     */
    callUpReserves(reserves: Reserve[]) {
        this.store.dispatch(new CallUpReservesAction(reserves));
    }

    /**
     * Skip the reserve call up
     */
    skipReserveCallUp() {
        this.store.dispatch(new SkipReserveCallUpAction());
    }

    /**
     * Player joins the game
     */
    joinGame() {
        this.store.dispatch(new JoinAction());
    }

    /**
     * Player joins a team
     */
    joinTeam(teamId: number) {
        this.store.dispatch(new JoinTeamAction(teamId));
    }

    /**
     * Player leaves the game
     */
    leaveGame() {
        this.store.dispatch(new LeaveAction());
    }

    /**
     * Updates the color preferences
     * @param playerId player to update the color
     * @param colorId  new color
     */
    updatePlayerColor(playerId: number, colorId: string) {
        this.store.dispatch(new SetPlayerColorAction(playerId, colorId));
    }

    /**
     * Sends a chat message
     * @param message message to be sent
     * @param scope   scope of the message
     */
    sendChatMessage(message: string, scope: 'self' | 'team' | 'game') {
        this.store.dispatch(new SendChatMessageAction(message, scope));
    }

    /**
     * Set from region
     * @param regionId from region id
     */
    setFromRegion(regionId: string) {
        this.mapFromRegionId$.next(regionId);
    }

    /**
     * Set to region
     * @param regionId to region id
     */
    setToRegion(regionId: string) {
        this.mapToRegionId$.next(regionId);
    }

    /**
     * Close the debriefing popup
     */
    closeDebriefPopup() {
        this.debriefPopupVisible$.next(false);
        this.debriefPopupVisible$.complete();
    }

    /**
     * Player return to base
     */
    returnToBase() {
        this.store.dispatch(new ReturnToBaseAction());
    }


    /* QUERY */

    /**
     * Return game status
     */
    getStatus(): Observable<GameStatus> {
        return this.status$;
    }

    /**
     * Return the remaining turn time in seconds
     */
    getTurnRemainingTime(): Observable<number> {
        // console.log('remainingTime', this.remainingTime$);
        return this.remainingTime$;
    }

    /**
     * Returns the current round number
     */
    getRoundNumber(): Observable<number> {
        return this.round$.pipe(
            map(round => round.number)
        );
    }

    /**
     * Return the list of players
     */
    getPlayers(): Observable<GamePlayer[]> {
        return this.players$;
    }

    /**
     * Returns the current player
     */
    getCurrentPlayer(): Observable<GamePlayer> {
        return this.currentPlayer$;
    }

    /**
     * Returns the action button state
     */
    getActionButtonState(): Observable<ActionButtonState> {
        return combineLatest(
            this.status$,
            this.currentPlayer$,
            this.selfPlayer$,
            this.isReserveCallUpMandatory$
        ).pipe(
            map(([status, currentPlayer, selfPlayer, callUpMandatory]: [GameStatus, GamePlayer, GamePlayer, boolean]) => {
                return this.actionButtonResolver.getActionState(
                    status,
                    selfPlayer !== null,
                    currentPlayer && selfPlayer ? currentPlayer.id === selfPlayer.id : false,
                    currentPlayer ? currentPlayer.deferredTroops > 0 : false,
                    callUpMandatory
                );
            }),
            debounceTime(500),
            tag('actionButton')
        );
    }

    /**
     * Returns the computed status message
     */
    getStatusMessage(): Observable<string> {
        return combineLatest(
            this.status$,
            this.playerId$,
            this.currentPlayer$
        ).pipe(
            map(([status, playerId, currentPlayer]: [GameStatus, number, GamePlayer]) => {
                const playersTurn = currentPlayer && currentPlayer.id === playerId;
                return this.statusMessageResolver.getMessage(status, playersTurn, currentPlayer);
            }),
            tag('statusMessage')
        );
    }

    /**
     * Returns the next reserve call up counter
     */
    getNextReserveCount(): Observable<number> {
        return this.troopsOnCallUp$;
    }

    /**
     * Get from region
     */
    getFromRegion(): Observable<MapRegion> {
        return this.fromRegion$;
    }

    /**
     * Get to region
     */
    getToRegion(): Observable<MapRegion> {
        return this.toRegion$;
    }

    /**
     * Return reserves for the player
     */
    getPlayerReserves(): Observable<Reserve[]> {
        return this.playerReserves$;
    }

    /**
     * Return players intel
     */
    getPlayersIntel(): Observable<GamePlayerIntel[]> {
        return this.playersIntel$;
    }

    /**
     * Retrieve the chat messages
     */
    getChatMessages(): Observable<ChatMessage[]> {
        return this.chatMessages$;
    }

    /**
     * Returns the visibility of the debrief popup
     */
    isDebriefPopupVisible(): Observable<boolean> {
        return this.debriefPopupVisible$.asObservable();
    }

    /**
     * Returns the visibility of the reserve callup popup
     */
    isReserveCallUpPopupVisible(): Observable<boolean> {
        return this.reserveCallUpPopupVisible$;
    }

    /**
     * Returns the visibility of the deploy popup
     */
    isDeployPopupVisible(): Observable<boolean> {
        return this.deployPopupVisible$;
    }

    /**
     * Returns the visibility of the assault popup
     */
    isAssaultPopupVisible(): Observable<boolean> {
        return this.assaultPopupVisible$;
    }

    /**
     * Returns the visibility of the deploy popup
     */
    isReinforcePopupVisible(): Observable<boolean> {
        return this.reinforcePopupVisible$;
    }

    /**
     * Returns the visibility of the victory popup
     */
    isVictoryPopupVisible(): Observable<boolean> {
        return this.victoryPopupVisible$;
    }

    /**
     * Returns the visibility of the paused popup
     */
    isPausedPopupVisible(): Observable<boolean> {
        return this.pausedPopupVisible$;
    }

    /**
     * Returns the visibility of the cancelled popup
     */
    isCancelledPopupVisible(): Observable<boolean> {
        return this.cancelledPopupVisible$;
    }

    /**
     * Returns the current loading progress
     */
    getLoadingProgress(): Observable<number> {
        return this.progressService.getProgress();
    }

    /**
     * Returns the game options
     */
    getGameOptions(): Observable<GameOptions> {
        return this.gameOptions$;
    }

    /**
     * Returns the troops due
     */
    getTroopsDue(): Observable<number> {
        return this.troopsDue$;
    }

    /**
     * Returns the attack results
     */
    getAttackResults(): Observable<AttackInfo[]> {
        return this.attacks$;
    }

    /** Map Related **/

    /**
     * Returns the current state for the map
     */
    getMapState(): Observable<MapState> {
        return this.mapState$;
    }

    /**
     * Returns information about the map
     */
    getMapInfo(): Observable<MapInfo> {
        return this.mapInfo$;
    }

    /**
     * Returns the map UI representation
     */
    getMapRepresentation(): Observable<UIMap> {
        return this.mapRepresentation$;
    }

    /**
     * Returns the regions canvas objects
     */
    getRegionsCanvasObjects(): Observable<any> { // TODO: typing
        return this.regionsCanvasObjects$;
    }

    /**
     * Returns the titles canvas objects
     */
    getTitlesCanvasObjects(): Observable<any> { // TODO: typing
        return this.titlesCanvasObjects$;
    }

    /**
     * Returns the containers canvas objects
     */
    getContainersCanvasObjects(): Observable<any> { // TODO: typing
        return this.containersCanvasObjects$;
    }

    /**
     * Returns if the reserve call up is mandatory or not
     */
    isReserveCallUpMandatory(): Observable<boolean> {
        return this.isReserveCallUpMandatory$;
    }

    /**
     * Get Game Record
     */
    // Todo getting record inside Records Tab
    getRecord(): Observable<any> {
        return this.record$.pipe(
            filter(record => record !== null && record.length > 0),
            debounceTime(100),
            map(record => {
                return record
                    .filter(r => r.kind !== 'chat_updated')
                    .sort((r1, r2) => r2.seq - r1.seq)
                ;
            })
        );
    }

    /**
     * Return the players colors
     */
    getPlayersColors(): Observable<any> {
        return this.playerColors$.pipe(
            filter(notNull),
            shareReplay(1)
        );
    }

    /**
     * Returns the container for a given player
     */
    getContainerImageForPlayer(playerId: number): Observable<any> {
        return combineLatest(
            this.containersImages$,
            this.getPlayersColors()
        ).pipe(
            map(([containers, playersColors]) => {
                const containerId = playersColors[playerId]; // TODO: if not present

                return containerId && containers[containerId] ? containers[containerId] : CONTAINER_EMPTY_IMG;
            })
        );
    }

    getContainersImages(): Observable<any> {
        return this.containersImages$.pipe(
            filter(notNull)
        );
    }

    getRegionName(regionId: string): Observable<string> {
        return this.mapInfo$.pipe(
            map((mapInfo: MapInfo) => {
                const idx = mapInfo.data.regions.findIndex(region => region.id === regionId);
                return mapInfo.data.regions[idx].name;
            }),
            first()
        );
    }

    getPlayerName(playerId: number): Observable<string> {
        return this.players$.pipe(
            map((players: GamePlayer[]) => {
                if (playerId === 0) {
                    return 'Neutral';
                }

                const idx = players.findIndex(player => player.id === playerId);
                return players[idx].name;
            }),
            first()
        );
    }

    getPlayerColor(playerId: number): Observable<string> {
        return this.playerColors$.pipe(
            filter(notNull),
            map((colors: any[]) => {
                return colors[playerId] ? colors[playerId] : null;
            })
        );
    }

    getPlayerRegions(playerId: number): Observable<string[]> {
        return this.players$.pipe(
            map((players: GamePlayer[]) => {
                const idx = players.findIndex(player => player.id === playerId);
                return idx > -1 ? players[idx].regions : [];
            })
        );
    }

    toggleChatter(visible: boolean) {
        this.chatterOpen$.next(visible);
    }

    getUnreadMessages(): Observable<any[]> {
        return this.unreadMessages$;
    }

    getTotalUnreadMessages(): Observable<number> {
        return this.unreadMessages$.pipe(
            map(messages => messages.length)
        );
    }

    isChatEnabled(): Observable<boolean> {
        return this.status$.pipe(
            map(status => status !== GameStatus.Paused)
        );
    }

    isPlayerAwol(): Observable<boolean> {
        return combineLatest(
            this.selfPlayer$.pipe(
                filter(notNull)
            ),
            this.status$
        ).pipe(
            map(([player, status]: [GamePlayer, GameStatus]) => {
                return player.state === PlayerStatus.AWOL && (status !== GameStatus.Cancelled && status !== GameStatus.Ended);
            })
        );
    }

    isDeferredDeployment(): Observable<boolean> {
        return this.isDeferredDeployment$;
    }

    clearAttacks(): void {
        this.store.dispatch(new ClearAttacksAction());
    }

    getTargetPlayer(): Observable<number> {
        return this.targetPlayer$;
    }

    getSelfPlayer(): Observable<GamePlayer> {
        return this.selfPlayer$;
    }

    getLastDefeatedPlayer(): Observable<GamePlayer> {
        return this.lastDefeatedPlayer$;
    }

    getTurnsTaken(): Observable<number> {
        return this.turnsTaken$;
    }

    getTimeTaken(): Observable<number> {
        return this.timeTaken$;
    }

    getPointsAwarded(): Observable<any> {
        return this.pointsAwarded$;
    }

    getTeams(): Observable<GameTeam[]> {
        return this.teams$;
    }

    getTeamSize(): Observable<number> {
        return this.teamSize$;
    }

    getCancelledMessage(): Observable<string> {
        return this.cancelledMessage$;
    }

    ngOnDestroy(): void {
        this.chatterOpen$.complete();

        this.onDestroy$.next(true);
        this.onDestroy$.complete();
    }
}

