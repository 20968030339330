import { ChangeDetectorRef, Component, OnInit, Input } from '@angular/core';
import { UserService } from '../services/user.service';
import { environment } from '@env/environment';
import { HttpClient, HttpParams } from '@angular/common/http';
import { GameService } from '@gamelist/services/api/game.service';
// import { BehaviorSubject, combineLatest, interval, merge, Observable, Subject } from 'rxjs';
import { CookieService } from 'ngx-cookie-service';
import { ModalService } from '@core/services/modal.service';
import { SharedService } from '../services/shared.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  @Input() loginValues: any = {};
  formData = {
    username: '',
    password: ''
  };
  backendApiUrl: string;
  siteUrl: string;
  logginErrorMessage: any = '';
  subscription_level: number = 0;
  constructor(
      private user : UserService,
      private http: HttpClient,
      private gameService: GameService,
      private cookieService: CookieService,
      private modalService: ModalService,
      private sharedService: SharedService,
      private router: Router,
      private changeDetectorRef: ChangeDetectorRef
  ) { }

  // state for handling the form or users info on dog card of the the main header.
  isLoggedIn: boolean = false;
  userId = null;
  cookieData: any = null;
  activeGames$;
  loggedInUserData: any = {};
  
  // Todo: add a method to handle the User Login API

  // Create a new service to store all the data for the currently logged in users data.


  ngOnInit() {

    this.backendApiUrl = environment.apiBaseUrl;
    this.siteUrl = environment.websiteUrl;

    let isLoggedInData = this.sharedService.checkLoggedinUserData();
    
    this.userId = isLoggedInData.userId;
    this.isLoggedIn = isLoggedInData.isUserLoggedIn;
    if (this.userId)
    {
      this.gameService.getLoggedinUserData(this.userId).subscribe(val => {
          this.loggedInUserData = val.user_data;
      });
    }
    this.getActivePlayerGames().subscribe(res => {
      
    });
  }

  getActivePlayerGames() {
      let params = new HttpParams();
      params = params.append('state', 'running,paused');

      return this.http.get(
        `${environment.apiUrl}/me/games`, { withCredentials: true, params }
      );
  }

  valueFromSignupPopup: any = {};
  updateLoginValue(newValue: any) {
      
      this.renderloggedInUserData(newValue);
  }
  
  renderloggedInUserData(userData: any) {
    this.loggedInUserData = userData;

    this.cookieService.set('apiType', 'pull');
    this.cookieService.set('userid', this.loggedInUserData.userid);
    this.cookieService.set('cookieAuth', this.loggedInUserData.cookieAuth);
    this.cookieService.set('PHPSESSID', this.loggedInUserData.PHPSESSID);

    this.isLoggedIn = true;
    this.userId = this.loggedInUserData.userid;
    this.subscription_level = this.loggedInUserData.subscription_level;
  }

  login() {
    this.logginErrorMessage = '';
    let payload = this.formData;
    if (payload.password && payload.username)
    {
      payload['functionName'] = 'apiLogin';
      this.user.initLogin(payload).subscribe((response: any) => {
        
        if (response.success) {
          this.renderloggedInUserData(response.user_data);
          this.router.navigate(['/beta']);
        } else {
          this.logginErrorMessage = response.message;
        }
        this.changeDetectorRef.detectChanges(); // Manually trigger change detection
      });
    }
  }
  
  profileDescription() {
    const username = this.loggedInUserData.username;
    
    window.location.href=`${this.backendApiUrl}/user/${username}/`;
  }
  
  openSignupPopup() {
    
    this.modalService.open('register-popup');
  }

  logout()
  {
    console.log('logout');
    this.cookieService.delete('apiType');
    this.cookieService.delete('userid');
    this.cookieService.delete('cookieAuth');
    this.cookieService.delete('PHPSESSID');

    this.user.initLogout().subscribe((response) => {
      window.location.href='/';
    })
    
  }
}
