import { Component, OnInit } from '@angular/core';
import { environment } from '@env/environment';
import { GameService } from '@gamelist/services/api/game.service';
import { Router } from '@angular/router';
import { SharedService } from 'src/app/services/shared.service';
import { BehaviorSubject, Observable } from 'rxjs';

@Component({
    selector: 'app-dashboard',
    templateUrl: './dashboard.component.html',
    styleUrls: ['./dashboard.component.scss']
})

export class DashboardComponent implements OnInit {

    selectedGameType = null;
    dogtagData: any = {};
    dogtagData$: Observable<any>;
    dispatchData: any = {};
    dispatchData$: Observable<any>;
    activeGames$: Observable<any>;
    awaitingGames$: Observable<any>;
    archivedGames$: Observable<any>;
    selectedTab = 'active';
    subscription_level: number = 0;

    // activeGamesRealTime$: any[] = []; // currently unsed
    active: boolean = true; 

    constructor(
        private gameService: GameService,
        private sharedService: SharedService,
        private router: Router
    ) { }

    ngOnInit() {
        // Fetch logged-in user data
        let isLoggedInData = this.sharedService.checkLoggedinUserData();
        this.subscription_level = isLoggedInData.subscription_level;

        // Fetch active, awaiting, and archived games data
        this.activeGames$ = this.gameService.getActivePlayerGames();
        this.awaitingGames$ = this.gameService.getAwaitingPlayerGames();
        this.archivedGames$ = this.gameService.getArchivedPlayerGames();

        // Fetch dispatch data from the API
        this.dispatchData$ = this.gameService.getDispatchData();
        this.dispatchData$.subscribe(val => {
               this.dispatchData = val;
    });

        // Fetch dog tag data (if needed)
        this.dogtagData$ = this.gameService.getDogTagData();
        this.dogtagData$.subscribe(val => {
            this.dogtagData = val;
        });
    }

        // Fallback logic for image if the advertisement image fails to load
        onImageError(event: any) {
            event.target.src = 'https://blog.majorcommand.com/wp-content/uploads/2024/03/dispatch.gif';
        }

    selectGameType(gameType: string) {
        this.selectedGameType = gameType;
    }

    // check to see if real-time subscription level are still needed an valid
    startGame(type: string) {
        if (type === 'realtime' && this.subscription_level !== 2) {
            this.router.navigate(['/beta/search'], { queryParams: { type: type, available_slots: 1, 'subscription': 'no' } });
        } else {
            this.router.navigate(['/beta/new'], { queryParams: { type: type } });
        }
    }
    
    joinGame(type: string) {
        this.router.navigate(['/beta/search'], { queryParams: { type: type, available_slots: 1 } });
    }

    searchGame() {
        this.router.navigate(['/beta/search'], { queryParams: { showJoinedGames: true } });
    }

    selectTab(tab: string) {
        this.selectedTab = tab;
    }
}
