import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { create } from 'rxjs-spy';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { HeaderComponent } from './header/header.component';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {

    private onDestroy$ = new Subject<boolean>();

    gameId: number;
    currentUrl: string;

    constructor(private route: ActivatedRoute, private translate: TranslateService) {
        // const spy = create();
        // spy.log('containersCanvasObjects');
        // spy.log('titlesCanvasObjects');
        // spy.log('playerId');
        // spy.log('troopsOnCallUp');
        translate.setDefaultLang('en');
        translate.use('en');
    }
    @ViewChild(HeaderComponent) headerComponent: HeaderComponent;
    
    ngOnInit(): void {
        
        this.route.queryParams
            .pipe(
                takeUntil(this.onDestroy$)
            )
            .subscribe(params => {
                this.gameId = +params.game;
                
            })
        ;
    }
    ngAfterViewInit(): void {
        window.addEventListener('load', () => {
            this.currentUrl = window.location.href;
            if (this.currentUrl.includes('=logout'))
            {
                this.headerComponent.logout();
            } else if (this.currentUrl.includes('=register'))
            {
                this.headerComponent.openSignupPopup();
            }
            // console.log(this.currentUrl);
        });
      }
    ngOnDestroy(): void {
        this.onDestroy$.next(true);
        this.onDestroy$.complete();

    }
}
