import { BrowserModule } from '@angular/platform-browser';
import { ErrorHandler, Injectable, NgModule } from '@angular/core';

import { AppComponent } from './app.component';
import { NgxsModule } from '@ngxs/store';
import { NgxsReduxDevtoolsPluginModule } from '@ngxs/devtools-plugin';
import { GameModule } from '@game/game.module';
import { StoreGameState } from '@game/store/states/game.state';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

import { RouterModule, Routes, RouteReuseStrategy, UrlSerializer } from '@angular/router';
import { CustomRouteReuseStrategy } from './custom-route-reuse-strategy';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastrModule } from 'ngx-toastr';

import { environment } from '@env/environment';
import * as Sentry from "@sentry/browser";
import { GameAreaComponent } from '@game/pages/game-area/game-area.component';
import { GameListModule } from '@gamelist/game-list.module';
import { NewGameComponent } from '@gamelist/pages/new-game/new-game.component';
import { DashboardComponent } from '@gamelist/pages/dashboard/dashboard.component';
import { GameSearchComponent } from '@gamelist/pages/game-search/game-search.component';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { ScoreboardComponent } from '@gamelist/pages/scoreboard/scoreboard.component';
import { AppRoutingModule } from './app-routing.module';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { NavMenuComponent } from './nav-menu/nav-menu.component';
import { FormsModule } from '@angular/forms'; // <-- Add this line
import { CookieService } from 'ngx-cookie-service';
import { AuthGuard } from './guard/auth.guard';
import { LoaderComponent } from '@game/components/loader/loader.component';
import { LoaderInterceptor } from './interceptors/loader.interceptor';
import { LoaderService } from './services/loader.service';
import { HomeComponent } from './modules/home/home.component';
import { LowerCaseUrlSerializer } from './UrlMatcher';

Sentry.init({
    dsn: environment.sentryDSN,
    debug: !environment.production,
    environment: environment.envName
});

@Injectable()
export class SentryErrorHandler implements ErrorHandler {
    constructor() {}
    handleError(error) {
        console.error(error);
        Sentry.captureException(error.originalError || error);
        throw error;
    }
}

export function HttpLoaderFactory(http: HttpClient) {
    return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

const appRoutes: Routes = [
  { path: '', component: HomeComponent },
  { path: 'beta', component: DashboardComponent, canActivate: [AuthGuard] },
  { path: 'beta/new', component: NewGameComponent, canActivate: [AuthGuard] },
  { path: 'beta/search', component: GameSearchComponent, canActivate: [AuthGuard]},
  { path: 'beta/:id', component: GameAreaComponent, canActivate: [AuthGuard] },
  { path: 'scoreboard', component: ScoreboardComponent, canActivate: [AuthGuard] },
   // Wildcard route for handling 404 errors
   { path: '**', redirectTo: '/beta' }, // Redirect to the home page
];

@NgModule({
    declarations: [
        AppComponent,
        HeaderComponent,
        FooterComponent,
        NavMenuComponent,
        LoaderComponent,
        HomeComponent
    ],
    imports: [
        NgxsModule.forRoot([
            StoreGameState
        ]),
        NgxsReduxDevtoolsPluginModule.forRoot(),
        BrowserModule,
        BrowserAnimationsModule,
        HttpClientModule,
        GameModule,
        // AppRoutingModule,
        RouterModule.forRoot(
            appRoutes, {
                onSameUrlNavigation: 'reload', // This line ensures the component is reloaded when navigating to the same URL with different parameters.
              }
           // { enableTracing: true }
        ),
        ToastrModule.forRoot({
            maxOpened: 3,
            autoDismiss: true
        }),
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            }
        }),
        GameListModule,
        FormsModule
    ],
    
    providers: [
        { provide: ErrorHandler, useClass: SentryErrorHandler }, 
        { provide: RouteReuseStrategy, useClass: CustomRouteReuseStrategy },
        CookieService,
        LoaderService,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: LoaderInterceptor,
            multi: true
        },
        {
            provide: UrlSerializer,
            useClass: LowerCaseUrlSerializer
        }
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
