import { Injectable } from '@angular/core';
import { Message } from '@game/interfaces/message';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '@env/environment';
import {
    AssaultEndedEvent,
    AssaultStartedEvent,
    ChatUpdatedEvent,
    DeferredTroopsDeploymentEndedEvent,
    DeferredTroopsDeploymentStartedEvent,
    DeploymentEndedEvent,
    DeploymentStartedEvent,
    GameCancelledEvent,
    GameEndedEvent,
    GamePausedEvent, GamePreferencesUpdatedEvent, GameRecordUpdatedEvent,
    GameResumedEvent,
    GameStartedEvent, GameUpdatedEvent,
    PlayerBecameActiveEvent,
    PlayerDefeatedEvent,
    PlayerGoneAWOLEvent,
    PlayerJoinedEvent,
    PlayerLeftEvent,
    PlayerReturnedToBaseEvent,
    PointsAwardedDueToPlayerDefeatEvent,
    RegionAdvancedEvent,
    RegionAttackedEvent, RegionAutoDeployedEvent,
    RegionBlitzedEvent,
    RegionBombardedEvent,
    RegionConqueredEvent,
    RegionReinforcedEvent, ReinforceableRegionsUpdatedEvent,
    ReinforcementEndedEvent,
    ReinforcementStartedEvent,
    ReserveAwardedEvent,
    ReserveCallUpEndedEvent,
    ReserveCallUpStartedEvent, ReserveSetCalledUpEvent,
    RoundEndedEvent,
    RoundStartedEvent,
    TroopsDeployedEvent,
    TurnBeganEvent,
    TurnEndedEvent, TurnStartedEvent, UpdateClockEvent
} from '@game/store/actions/events';
import { Store } from '@ngxs/store';
import { GetActiveGames, InitGameAction } from '@game/store/actions/actions';
// import { SharedService } from 'src/app/services/shared.service';

@Injectable({
    providedIn: 'root'
})
export class MessageHandlerService {

    private sideEffectsIdx = {
        'player_joined': PlayerJoinedEvent,
        'player_left': PlayerLeftEvent,

        'round_started': RoundStartedEvent,
        'round_ended': RoundEndedEvent,

        'turn_started': TurnStartedEvent,
        'turn_began': TurnBeganEvent,
        'turn_ended': TurnEndedEvent,

        'game_started': GameStartedEvent,
        'game_paused': GamePausedEvent,
        'game_resumed': GameResumedEvent,
        'game_ended': GameEndedEvent,
        'game_cancelled': GameCancelledEvent,

        'deployment_started': DeploymentStartedEvent,
        'troops_deployed': TroopsDeployedEvent,
        'deployment_ended': DeploymentEndedEvent,

        'assault_started': AssaultStartedEvent,
        'region_attacked': RegionAttackedEvent,
        'region_blitzed': RegionBlitzedEvent,
        'region_conquered': RegionConqueredEvent,
        'region_bombarded': RegionBombardedEvent,
        'region_advanced': RegionAdvancedEvent,
        'assault_ended': AssaultEndedEvent,

        'reinforcement_started': ReinforcementStartedEvent,
        'region_reinforced': RegionReinforcedEvent,
        'reinforcement_ended': ReinforcementEndedEvent,

        'player_defeated': PlayerDefeatedEvent,
        'points_awarded_due_to_player_defeat': PointsAwardedDueToPlayerDefeatEvent,
        'player_gone_awol': PlayerGoneAWOLEvent,
        'player_returned_to_base': PlayerReturnedToBaseEvent,
        'player_became_active': PlayerBecameActiveEvent,

        'deferred_troops_deployment_started': DeferredTroopsDeploymentStartedEvent,
        'deferred_troops_deployment_ended': DeferredTroopsDeploymentEndedEvent,

        'chat_updated': ChatUpdatedEvent,

        'reserve_awarded': ReserveAwardedEvent,
        'reserve_call_up_started': ReserveCallUpStartedEvent,
        'reserve_call_up_ended': ReserveCallUpEndedEvent,
        'reserve_set_called_up': ReserveSetCalledUpEvent,

        'region_auto_deployed': RegionAutoDeployedEvent
    };

    constructor(private store: Store,private http: HttpClient,) { }

    /**
     * Handles the messages received in the websocket
     */
    handleMessage(msg: Message) {

        // game update
        let messageType;

        if (msg.msg) {
            messageType = msg.msg;
        } else if (msg.update) {
            messageType = msg.update;
        } else if (msg.cmd) {
            messageType = msg.cmd;
        }

        switch (messageType) {
            case 'registration':
                this.store.dispatch(
                    new InitGameAction(+msg.user)
                );
                break;

            case 'current_game':
                this.store.dispatch(
                    new GameUpdatedEvent(msg.game)
                );
                break;
            

            case 'get_game_preferences':
            case 'set_game_preferences':
                // TODO: why? for some reason we are receiveing an empty get_game_preferences
                if (msg.result) {
                    const preferences = {
                        playerColors: msg.result.playerColors
                    };

                    if (msg.result.lastReadChat) {
                        preferences['lastReadChat'] = msg.result.lastReadChat;
                    }

                    this.store.dispatch(
                        new GamePreferencesUpdatedEvent(preferences)
                    );
                }
                break;

            case 'get_game_record':
                if (msg.result) {
                    this.store.dispatch(
                        new GameRecordUpdatedEvent(
                            // flatten array
                            [].concat.apply([], msg.result)
                        )
                    );
                }
                break;

            /*
            case 'get_posts':
                if (msg.result) {
                    this.store.dispatch(
                        new ChatUpdatedEvent(msg.result.messages)
                    );
                }
                break;
            */

            case 'get_reinforceable_regions':
                if (msg.result) {
                    this.store.dispatch(
                        new ReinforceableRegionsUpdatedEvent(msg.result.reinforcements)
                    );
                }
                break;

            case 'join':
            case 'begin_turn':
            case 'deploy':
            case 'attack':
            case 'blitz':
            case 'advance':
            case 'end_assault':
            case 'reinforce':
            case 'end_reinforcement':
            case 'call_up_reserves':
            case 'skip_reserve_call_up':
            case 'leave':
            case 'check_for_turn_end':
            case 'return_to_base':
            case 'resume':
            case 'skip_deployment':
            case 'post':
                // TODO: more?
                // console.log('handle msg other', msg);
                if (msg.fx && msg.fx.length > 0) {
                    this.processSideEffects(msg.fx);
                }
                break;

            // case 'post':
            //     console.log('handle msg case', msg.ok);
            //     if (!msg.ok) {
            //         throw msg.error.msg;
            //     }
            //     this.processSideEffects(msg.fx);
            //     break;
        }

        if ((messageType === 'current_game' || messageType === 'begin_turn') && msg.now) {
            // message contains a timestamp, update internal clock
            this.store.dispatch(new UpdateClockEvent(msg.now));
        }

        // if ((messageType != '')) {
        //     // message contains a timestamp, update internal clock
        //     // this.store.dispatch(new GetActiveGames());
        //     console.log(messageType, 'message-handle');
        //     let params = new HttpParams();
        //     params = params.append('state', 'running,paused');

        //     const newList = this.http.get(
        //         `${environment.apiUrl}/me/games`,
        //         { withCredentials: true, params }
        //     );
        //     // this.sharedService.updateList(newList);
        // }
    }

    private processSideEffects(fxs: any[]) {

        this.store.dispatch(new GameRecordUpdatedEvent(fxs));
        // console.log('processSideEffects', fxs);
        for (const fx of fxs) {

            if (this.sideEffectsIdx[fx.kind]) {
                // dispatch the event action
                this.store.dispatch(new this.sideEffectsIdx[fx.kind](fx));
            }
        }
    }
}
