import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { environment } from '@env/environment';
import { Observable } from 'rxjs';
import { PlayersService } from '@gamelist/services/api/players.service';
import { ModalService } from '@core/services/modal.service';
// import { HeaderComponent } from '../../../../header/header.component';

@Component({
    selector: 'app-game-advanced-search',
    templateUrl: './game-advanced-search.component.html',
    styleUrls: ['./game-advanced-search.component.scss']
})
export class GameAdvancedSearchComponent implements OnInit {

    @Input() options;
    selectedFilters = {
        maps: [],
        players: [],
        objectives: [],
        teams: [],
        reserves: [],
        reinforcementAllowance: [],
        reinforcement: [],
        force: [],
        // turnLength: [],
        privacy: [],
        gamePlayers: []
    };

    @Output() search = new EventEmitter();
    @Output() closed = new EventEmitter();

    constructor(private playersService: PlayersService, private modalService: ModalService) { }

    ngOnInit() {
    }

    getMapImage(map) {
        // ${map.version}
        return `${environment.mapsUrl}/${map.key}/1/${map.key}.jpg`;
    }

    toggleMap(map) {
        this.toggleOption('maps', map.key);
    }

    toggleOption(option, value) {
        if (this.selectedFilters[option].includes(value)) {
            this.selectedFilters[option] = this.selectedFilters[option].filter(_value => _value !== value);
        } else {
            this.selectedFilters[option].push(value);
        }
    }

    public searchPlayer = (username: string): Observable<any> => {
        return this.playersService.searchByUsername(username);
    }

    cancel() {
        console.log("cancel");
        // this.modalService.close('advanced-search');
        this.closed.emit();
    }

    doSearch() {
        const searchFilters = Object.assign(
            {},
            this.selectedFilters,
            { gamePlayers: this.selectedFilters.gamePlayers.map(player => player.id) }
        );
        this.search.emit(searchFilters);
        // this.modalService.close('advanced-search');
        this.closed.emit();
    }
}
