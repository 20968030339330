import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { Reserve } from '@game/store/models/game.model';
import { isArray } from 'util';
import { GamePlayerIntel } from '@game/interfaces/game-player-intel';

@Component({
    selector: 'app-intel',
    templateUrl: './intel.component.html',
    styleUrls: ['./intel.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class IntelComponent implements OnChanges {

    playersExpanded = new Set();
    allExpanded = false;
    onlyActivePlayers = false;
    isArray;
    teams;
    totalTeams: number;

    @Input() players: GamePlayerIntel[];
    @Input() playerReserves: Reserve[];
    @Input() nextReserveCount: number;
    @Input() containers: any;
    @Input() targetPlayer: number;

    @Output() playerColorUpdated = new EventEmitter<any>();

    constructor() {
        this.isArray = isArray;
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.players && changes.players.currentValue) {
            // organize information by teams
            const teams = this.players.reduce((acc, player) => {
                const teamId = player.team ? player.team : 1;
                const idx = teamId - 1;

                if (acc[idx]) {
                    acc[idx].players.push(player);
                } else {
                    acc[idx] = {
                        id: teamId,
                        players: [player]
                    };
                }
                return acc;
            }, []).filter(t => t != null);

            this.totalTeams = teams.length;

            if (this.totalTeams > 1) {
                // there are more than one team, so we compute the sum of players statistics
                for (const team of teams) {
                    
                    
                    team.total = team.players.reduce((acc, player) => {
                        acc.reserveCount += player.reserveCount ? player.reserveCount : 0;
                        acc.troopsCount += player.troopsCount;
                        acc.regionsCount += player.regionsCount;
                        acc.troopsDue += player.troopsDue ? player.troopsDue : 0;
                        acc.deferredTroops += player.deferredTroops;
                        acc.turnsMissed += player.turnsMissed ? player.turnsMissed.total : 0;
                        
                        return acc;
                        
                    }, {reserveCount: 0, troopsCount: 0, regionsCount: 0, troopsDue: 0, deferredTroops: 0, turnsMissed: 0});
                }
            }

            this.teams = teams;
            
        }
    }

    updatePlayerColor(playerId: number, colorId: string) {
        this.playerColorUpdated.emit({
            player: playerId,
            color: colorId
        });
    }

    togglePlayer(player) {
        if (this.isExpanded(player)) {
            this.playersExpanded.delete(player.id);
        } else {
            this.playersExpanded.add(player.id);
        }

        this.allExpanded = this.playersExpanded.size === this.players.length;
    }

    isExpanded(player) {
        return this.playersExpanded.has(player.id);
    }

    toggleAll() {
        this.allExpanded = !this.allExpanded;

        if (!this.allExpanded) {
            this.playersExpanded.clear();
        } else {
            this.playersExpanded = new Set(this.players.map(player => player.id));
        }
    }
}
