import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { GameService } from '@gamelist/services/api/game.service';
import { Subject } from 'rxjs';
import { share, startWith, switchMap, tap } from 'rxjs/operators';
import { ModalService } from '@core/services/modal.service';
import { ActivatedRoute } from '@angular/router';

@Component({
    selector: 'app-game-search',
    templateUrl: './game-search.component.html',
    styleUrls: ['./game-search.component.scss']
})
export class GameSearchComponent implements OnInit {
    
    showAdvanced : boolean = false;

    games$;
    filters$;
    selectedFilters = {
        status: ['slots_available', 'in_progress', 'archived'],
        turnLength: [86400, 300, 180],
        minSlots: null,
        maxSlots: null,
        minRank: null,
        maxRank: null,
        maps: [],
        players: [],
        objectives: [],
        teams: [],
        reserves: [],
        reinforcementAllowance: [],
        reinforcement: [],
        force: [],
        privacy: [],
        gamePlayers: [],
        isPlayer: false
    };
    selectedFilters$ = new Subject();

    @ViewChild('gameArea')
    gameArea: ElementRef;
    noSubscriptionMessage: string = '';

    constructor(private gameService: GameService, private modalService: ModalService, private route: ActivatedRoute) { }

    ngOnInit() {
        this.showAdvanced = false;

        const subscription = this.route.snapshot.queryParams.subscription;
        if (subscription != undefined && subscription == 'no')
        {
            this.noSubscriptionMessage = "Creating Real-time Games has been temporarily disabled.<br>  Please join one of the premade Real-time Games below.<br><br> Or feel free to request a game to be made by one of our admins.<br>  <a class='text-blue-600' href='https://forum.majorcommand.com/threads/real-time-game-requests-here.9746/'>Real Time Game Request Thread</a>";
        }
        
        const gameType = this.route.snapshot.queryParams.type;
        if (gameType === 'realtime') {
            this.selectedFilters.turnLength = [300, 180];
        } else if (gameType === 'casual') {
            this.selectedFilters.turnLength = [86400];
        }

        const onlyAvailable = this.route.snapshot.queryParams.available_slots === '1';
        // Commented for task #158625 FRONTEND - Make default uncheck "In Progress" and "Archived" boxes
        // if (onlyAvailable) {
            this.selectedFilters.status = ['slots_available'];
        // } else {
        //     this.selectedFilters.status = ['slots_available', 'in_progress', 'archived'];
        // }

        const showJoinedGames = this.route.snapshot.queryParams.showJoinedGames === 'true';
        this.selectedFilters.isPlayer = showJoinedGames;

        this.filters$ = this.gameService.getSearchParams().pipe(
            share()
        );


        // Todo: Make sure this run once the CORS issue is resolved from the clients end.
        // Searching the games on search page (Advanced Search)
        this.games$ = this.selectedFilters$.pipe(
            startWith(this.selectedFilters),
            switchMap(filters => this.gameService.listGames(filters))
        );
    }

    updateFilter(key, value) {
        this.selectedFilters[key] = value;
        this.applyFilters(this.selectedFilters);
    }

    toggleFilter(key, value) {
        if (this.selectedFilters[key].includes(value)) {
            this.selectedFilters[key] = this.selectedFilters[key].filter(_value => _value !== value);
        } else {
            this.selectedFilters[key].push(value);
        }
        this.applyFilters(this.selectedFilters);
    }

    applyFilters(filters) {
        this.selectedFilters$.next(filters);
    }

    showAdvancedSearch() {
        // this.gameArea.nativeElement.style.height = '1225px';
        // this.modalService.open('advanced-search');
        this.showAdvanced = true;
    }

    closeAdvanced() {
        this.showAdvanced = false;
    }

    applyAdvancedFilters(filters) {
        this.selectedFilters = Object.assign({}, this.selectedFilters, filters);
        this.applyFilters(this.selectedFilters);
    }

    readjustHeight() {
        this.gameArea.nativeElement.style.height = 'auto';
    }
}
