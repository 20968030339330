import { Injectable } from '@angular/core';
import { Howl } from 'howler';

@Injectable({
    providedIn: 'root'
})
export class SoundNotificationService {

    private howler;

    constructor() {
        this.howler = new Howl({
            src: ['assets/sounds/sound_sprite.mp3'],
            sprite: {
                'message_alert': [
                    0,
                    450.1587301587301
                ],
                'turn_alert': [
                    2000,
                    1000
                ]
            }
        });
    }

    playTurnNotificationAlert() {
        this.howler.play('turn_alert');
    }

    playMessageNotificationAlert() {
        this.howler.play('message_alert');
    }
}
