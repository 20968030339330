import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '@env/environment';
import { GameOptions } from '@gamelist/models/game-options';
import { WebsocketService } from '@core/services/websocket.service';
import { BehaviorSubject, combineLatest, interval, merge, Observable, Subject, of } from 'rxjs';
import { MessageHandlerService } from '@game/services/message-handler.service';
import { Select, Store } from '@ngxs/store';
import { GetActiveGames } from '@game/store/actions/actions';
import { ToastrService } from 'ngx-toastr';

@Injectable({
    providedIn: 'root'
})
export class GameService {
    private connectionOpenObserver$: Subject<any>;
    private connectionCloseObserver$: Subject<any>;
    private onDestroy$ = new Subject<boolean>();

    constructor(private http: HttpClient,
        private socketService: WebsocketService,
        private messageHandler: MessageHandlerService,
        private store: Store,
        private toastr: ToastrService,
        ) { 
            // subjects that will observe websocket service
            this.connectionOpenObserver$ = new Subject();
            this.connectionCloseObserver$ = new Subject();
        }

    getLoggedinUserData(userId: number): any {
        
        
        return this.http.post(`${environment.apiBaseUrl}/rest-api/restApiActions.php`, {userId: userId, 'functionName':'getLoggedInUserData'}, { withCredentials: true});
    }
    getDogTagData(): any {
        
        
        return this.http.get(`${environment.apiBaseUrl}/DogTag.php`, { withCredentials: true });
    }
    getDispatchData(): any {
        
        return this.http.get(`${environment.apiBaseUrl}/Dispatch.php`, { withCredentials: true });
    }
    getAvailableMaps(): any {
        return this.http.get(`${environment.apiUrl}/games/new`, { withCredentials: true });
    }

    createGame(options: GameOptions): any {
        return this.http.post(`${environment.apiUrl}/games`, options, { withCredentials: true });
    }

    listGames(filters = {}): any {
        return this.http.get(`${environment.apiUrl}/games`, { withCredentials: true, params: this.processQueryParams(filters) });
    }

    private processQueryParams(filters) {
        const params = {};

        if (filters.minSlots || filters.maxSlots) {
            params['empty_slots'] = this.rangeFilter(filters.minSlots, filters.maxSlots);
        }
        if (filters.minRank || filters.maxRank) {
            params['rank'] = this.rangeFilter(filters.minRank, filters.maxRank);
        }
        if (filters.maps.length > 0) {
            params['maps'] = filters.maps.join(',');
        }
        if (filters.players.length > 0) {
            params['players'] = filters.maps.join(',');
        }
        if (filters.objectives.length > 0) {
            params['objective'] = filters.objectives.join(',');
        }
        if (filters.teams.length > 0) {
            params['teams'] = filters.teams.join(',');
        }
        if (filters.reserves.length > 0) {
            params['reserves'] = filters.reserves.join(',');
        }
        if (filters.reinforcementAllowance.length > 0) {
            params['reinforcement_allowance'] = filters.reinforcementAllowance.join(',');
        }
        if (filters.reinforcement.length > 0) {
            params['reinforcement'] = filters.reinforcement.join(',');
        }
        if (filters.force.length > 0) {
            params['force'] = filters.force.join(',');
        }
        if (filters.turnLength.length > 0) {
            params['turn_length'] = filters.turnLength.join(',');
        }
        if (filters.privacy.length > 0) {
            params['privacy'] = filters.privacy.join(',');
        }
        if (filters.status.length > 0) {
            params['state'] = filters.status.reduce((acc, status) => {
                switch (status) {
                    case 'slots_available':
                        return [...acc, 'waiting'];
                    case 'in_progress':
                        return [...acc, 'running', 'paused'];
                    case 'archived':
                        return [...acc, 'ended', 'cancelled'];
                }
            }, []).join(',');
        }
        if (filters.gamePlayers.length > 0) {
            params['game_players'] = filters.gamePlayers.join(',');
        }

        params['is_player'] = filters.isPlayer ? 'yes' : 'no';

        return params;
    }

    private rangeFilter(min, max) {
        return `${min !== null ? min : ''}:${max !== null ? max : ''}`;
    }

    joinGame(gameId: number, teamId?: number): any {
        const params = { cmd: 'join' };
        if (teamId) {
            params['team'] = teamId;
        }
        return this.http.post(
            `${environment.apiUrl}/games/${gameId}`,
            params,
            { withCredentials: true, params: { ctx: 'list' } }
        );
    }

    leaveGame(gameId: number): any {
        return this.http.post(
            `${environment.apiUrl}/games/${gameId}`,
            { cmd: 'leave' },
            { withCredentials: true, params: { ctx: 'list' } }
        );
    }

    getActivePlayerGames() {
        let params = new HttpParams();
        params = params.append('state', 'running,paused');

        return this.http.get(
            `${environment.apiUrl}/me/games`,
            { withCredentials: true, params }
        );
    }

    getAwaitingPlayerGames() {
        let params = new HttpParams();
        params = params.append('state', 'waiting');

        return this.http.get(
            `${environment.apiUrl}/me/games`,
            { withCredentials: true, params }
        );
    }

    getArchivedPlayerGames() {
        let params = new HttpParams();
        params = params.append('state', 'ended');

        return this.http.get(
            `${environment.apiUrl}/me/games`,
            { withCredentials: true, params }
        );
    }

    getSearchParams() {
        return this.http.get(
            `${environment.apiUrl}/games/search`,
            { withCredentials: true }
        );
    }

    /**
     * Loads the game
     */
    connectWebsocket() {

        // connect socket
        // Todo: Websocket server connection
        this.socketService
        .connect(
            `${environment.serverUrl}/0/socket`,
            this.connectionOpenObserver$,
            this.connectionCloseObserver$
        )
        .subscribe(msg => {
            try {
                console.log('socket-message', msg);
                this.messageHandler.handleMessage(msg);
            } catch (e) {
                this.toastr.error(e);
            }
        })
    }
}
