import { Injectable } from '@angular/core';
import { bindCallback, forkJoin, Observable, Subject } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { environment } from '@env/environment';
import { MapAssets, MapData, MapDataContainer, MapDataRegion } from '@game/models/ui-map';

@Injectable({
    providedIn: 'root'
})
export class MapLoaderService {

    private mapAssets: MapAssets;

    // private mapData$ = new ReplaySubject<MapData>();
    private containersImages$ = new Subject();

    constructor(private httpService: HttpClient) { }

    /**
     * Load map
     */
    loadMap(name: string): Observable<any> {
        const baseUrl  = `${environment.mapsUrl}/${name}/1/${name}`;
        const infoFile = `${baseUrl}.json`;

        /* image assets */
        this.mapAssets  = {
            map:        `${baseUrl}.jpg`,
            brief:      `${baseUrl}-brief.jpg`,
            containers: `${baseUrl}-containers.png`,
            regions:    `${baseUrl}-regions.png`,
            titles:     `${baseUrl}-titles.png`
        };

        return this.httpService.get<MapData>(infoFile)
            .pipe(
                map(mapData => {
                    
                    return {
                        assets: this.mapAssets,
                        data: mapData
                    };
                })
            )
        ;
    }



    // TODO: typing
    getRegionsCanvasObjects(imageUrl: string, objectsInfo: MapDataRegion[]): Observable<any> {
        return this.loadImage(imageUrl).pipe(
            switchMap((regionsImage: HTMLImageElement) =>
                this.loadRegionsCanvasObjects(objectsInfo, regionsImage)
            )
        );
    }

    // TODO: typing
    getTitlesCanvasObjects(imageUrl: string, objectsInfo: MapDataRegion[]): Observable<any> {
        return this.loadImage(this.mapAssets.titles).pipe(
            switchMap((titlesImage: HTMLImageElement) =>
                this.loadTitlesCanvasObjects(objectsInfo, titlesImage)
            ),
            map(entries => {
                return entries.reduce((index, entry) => {
                    index[entry.region] = entry.obj;
                    return index;
                }, {});
            })
        );
    }

    // TODO: typing
    getContainersCanvasObjects(imageUrl: string, objectsInfo: MapDataContainer[]): Observable<any> {
        return this.loadImage(imageUrl).pipe(
            switchMap((containersImage: HTMLImageElement) =>
                this.loadContainersCanvasObjects(objectsInfo, containersImage)
            ),
            map(entries => {
                return entries.reduce((index, entry) => {
                    if (!index[entry.containerId]) {
                        index[entry.containerId] = {};
                    }
                    index[entry.containerId][entry.type] = entry.obj;
                    return index;
                }, {});
            })
        );
    }


    /**
     * Load region overlays into canvas
     */
    private loadRegionsCanvasObjects(regions: MapDataRegion[], regionsSprite: HTMLImageElement): Observable<any> {

        const tasksBlack$ = [];
        const tasksWhite$ = [];
        const tasksRed$ = [];

        const fn = bindCallback((img, options, cb) => {
            fabric.Image.fromURL(img, cb, options);
        });

        for (const region of regions) {

            // create fabric image from data url (regions' image)

            const blackRegionImg = this.getImageSprite(
                regionsSprite,
                region.image.w,
                region.image.h,
                region.sprite.regular.x,
                region.sprite.regular.y
            );

            const whiteRegionImg = this.getImageSprite(
                regionsSprite,
                region.image.w,
                region.image.h,
                region.sprite.white.x,
                region.sprite.white.y
            );

            const redRegionImg = this.getImageSprite(
                regionsSprite,
                region.image.w,
                region.image.h,
                region.sprite.red.x,
                region.sprite.red.y
            );

            // regions
            tasksBlack$.push(
                fn(blackRegionImg, {
                    top: region.image.y,
                    left: region.image.x,
                    perPixelTargetFind: true, // for pixel perfect object detection (not needed on overlay)
                    targetFindTolerance: 4,
                    selectable: false, // make sure its unselectable by dragging
                    opacity: 0.1,
                    type: 'region', // adding type, for easier filtering object
                    name: region.id, // naming object, for easier lookup
                    cursor: 'default'

                    // TODO: this object logic should be on the map component?
                })
                    .pipe(
                        map(obj => {
                            return {
                                regionId: region.id,
                                obj
                            };
                        })
                    )
            );

            // white overlays
            tasksWhite$.push(
                fn(whiteRegionImg, {
                    top: region.image.y,
                    left: region.image.x,
                    selectable: false, // make sure its unselectable by dragging
                    type: 'white-overlay', // adding type, for easier filtering object
                    name: region.id + '-adjacent' // naming object, for easier lookup
                })
                    .pipe(
                        map(obj => {
                            return {
                                regionId: region.id,
                                obj
                            };
                        })
                    )
            );

            // red overlays
            tasksRed$.push(
                fn(redRegionImg, {
                    top: region.image.y,
                    left: region.image.x,
                    selectable: false, // make sure its unselectable by dragging
                    type: 'red-overlay', // adding type, for easier filtering object
                    name: region.id + '-overlay' // naming object, for easier lookup
                })
                    .pipe(
                        map(obj => {
                            return {
                                regionId: region.id,
                                obj
                            };
                        })
                    )
            );
        }

        // wait for all canvas objects to finish load to render canvas
        return forkJoin(
            forkJoin(tasksBlack$).pipe(
                map(arr => {
                    return arr.reduce((idx, entry) => {
                        idx[entry.regionId] = entry.obj;
                        return idx;
                    }, {});
                })
            ),
            forkJoin(tasksWhite$).pipe(
                map(arr => {
                    return arr.reduce((idx, entry) => {
                        idx[entry.regionId] = entry.obj;
                        return idx;
                    }, {});
                })
            ),
            forkJoin(tasksRed$).pipe(
                map(arr => {
                    return arr.reduce((idx, entry) => {
                        idx[entry.regionId] = entry.obj;
                        return idx;
                    }, {});
                })
            )
        )
            .pipe(
                map(([black, white, red]) => {
                   return {
                       black,
                       white,
                       red
                   };
                })
            )
        ;
    }

    /**
     * Load titles overlays
     */
    private loadTitlesCanvasObjects(regions: MapDataRegion[], titlesSprite: HTMLImageElement): Observable<any> {

        const tasks$ = [];

        const fn = bindCallback((img, options, cb) => {
            fabric.Image.fromURL(img, cb, options);
        });

        for (const region of regions) {

            // create fabric image from data url (title image)

            const titleImg = this.getImageSprite(
                titlesSprite,
                region.title.w,
                region.title.h,
                region.sprite.title.x,
                region.sprite.title.y
            );

            // titles overlay
            tasks$.push(
                fn(titleImg, {
                    top: region.title.y,
                    left: region.title.x,
                    selectable: false, // make sure its unselectable by dragging
                    type: 'title-overlay', // adding type, for easier filtering object
                    name: region.id + '-title' // naming object, for easier lookup
                })
                    .pipe(
                        map(obj => {
                            return {
                                region: region.id,
                                obj
                            };
                        })
                    )
            );
        }

        // wait for all canvas objects to finish load to render canvas
        return forkJoin(tasks$);
    }

    /**
     * Load container overlays objects
     */
    private loadContainersCanvasObjects(containers: any, containersSprite: HTMLImageElement): Observable<any> {

        // TODO: optimize in order to only loaded the needed containers. It may change during the game if player changes his preferences

        const tasks$ = [];

        const fn = bindCallback((img, options, cb) => {
            fabric.Image.fromURL(img, cb, options);
        });

        const containerImages = {};

        for (const container of containers) {

            // create fabric image from data url (containers' image)

            // 2 digit container images
            const container2DigitsImg = this.getImageSprite(
                containersSprite,
                container['2digit'].w,
                container['2digit'].h,
                container['2digit'].x,
                container['2digit'].y
            );

            // 3 digit container images
            const container3DigitsImg = this.getImageSprite(
                containersSprite,
                container['3digit'].w,
                container['3digit'].h,
                container['3digit'].x,
                container['3digit'].y
            );

            containerImages[container.id] = container2DigitsImg;

            // 2 digit containers
            tasks$.push(
                fn(container2DigitsImg, {
                    originX: 'center',
                    originY: 'center',
                    selectable: false, // make sure its unselectable by dragging
                    type: 'container-overlay', // adding type, for easier filtering object
                    name: container.id + '-container' // naming object, for easier lookup
                })
                    .pipe(
                        map(obj => {
                            return {
                                containerId: container.id,
                                type: '2digit',
                                obj
                            };
                        })
                    )
            );

            // 3 digit containers
            tasks$.push(
                fn(container3DigitsImg, {
                    originX: 'center',
                    originY: 'center',
                    selectable: false, // make sure its unselectable by dragging
                    type: 'container-overlay', // adding type, for easier filtering object
                    name: container.id + '-container' // naming object, for easier lookup
                })
                    .pipe(
                        map(obj => {
                            return {
                                containerId: container.id,
                                type: '3digit',
                                obj
                            };
                        })
                    )
            );
        }

        this.containersImages$.next(containerImages);

        return forkJoin(tasks$);
    }

    getContainersImages(): Observable<any> {
        return this.containersImages$.asObservable();
    }

    /**
     * Auxiliary function to return an image from the sprite as base64
     */
    private getImageSprite(image: any, width: number, height: number, x: number, y: number) {
        const tmpCanvas = fabric.util.createCanvasElement();
        tmpCanvas.width = width;
        tmpCanvas.height = height;

        // get 2d context from canvas and clear canvas
        const tmpCtx = tmpCanvas.getContext('2d');
        tmpCtx.clearRect(0, 0, tmpCanvas.width, tmpCanvas.height);

        // draw image from map to canvas with coordinate from json
        tmpCtx.drawImage(
            image,
            -1 * x,
            -1 * y
        );

        // convert canvas to data url
        return tmpCanvas.toDataURL('image/png');
    }

    /**
     * Auxiliary function to load an image
     */
    private loadImage(imageUrl: string): Observable<HTMLImageElement> {
        return Observable.create((observer) => {
            const img = new Image();
            img.src = imageUrl;
            img.crossOrigin = 'Anonymous'; // hack to allow image to be loaded from a different domain
            img.onload = () => {
                observer.next(img);
                observer.complete();
            };
            img.onerror = (err) => {
                observer.error(err);
            };
        });
    }
}
