import { Component, OnInit } from '@angular/core';
import { environment } from '@env/environment';
import { ScoreboardService } from '@gamelist/services/api/scoreboard.service';

@Component({
    selector: 'app-scoreboard',
    templateUrl: './scoreboard.component.html',
    styleUrls: ['./scoreboard.component.scss']
})
export class ScoreboardComponent implements OnInit {

    pointsLeaderboardCurrent$;
    pointsLeaderboardAllTime$;
    /* consecutiveDefeatedOpponentsCurrent$;
    consecutiveDefeatedOpponentsAlltime$;
    mostDecoratedPlayers$;
    mostOpponentsDefeated$;
    newUsers$;
    totalActiveUsers$; */
    selectedScoreboard = 'points_leaderboard_current';
    static readonly recordSize : number = 100;
    static readonly allTimeframe : string = "alltime";
    apiUrl: string;

    constructor(private scoreboardService: ScoreboardService) { }

    ngOnInit() {
        this.apiUrl = environment.websiteUrl
        this.pointsLeaderboardCurrent$ = this.scoreboardService.getPointLeaderBoardCurrent(ScoreboardComponent.recordSize);
        this.pointsLeaderboardAllTime$ = this.scoreboardService.getPointLeaderBoardAll(ScoreboardComponent.allTimeframe);
        /* this.consecutiveDefeatedOpponentsAlltime$ = this.scoreboardService.getConsecutiveDefeatedOpponents('alltime');
        this.consecutiveDefeatedOpponentsCurrent$ = this.scoreboardService.getConsecutiveDefeatedOpponents('current');
        this.mostDecoratedPlayers$ = this.scoreboardService.getMostDecoratedPlayers();
        this.mostOpponentsDefeated$ = this.scoreboardService.getMostOpponentsDefeated();
        this.newUsers$ = this.scoreboardService.getNewUsers();
        this.totalActiveUsers$ = this.scoreboardService.getTotalActiveUsers(); */
    }

}
