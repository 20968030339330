import { Component, HostListener, Input, OnDestroy, OnInit } from '@angular/core';
import { BehaviorSubject, Observable, Subject, timer } from 'rxjs';
import { AttackInfo, GameOptions, GamePlayer, GameStatus, GameTeam, Reserve } from '@game/store/models/game.model';
import { GameFacadeService } from '@game/facades/game-facade.service';
import { ActionButtonState } from '@game/util/action-button-resolver.service';
import { GamePlayerIntel } from '@game/interfaces/game-player-intel';
import { MapState } from '@game/models/map-state';
import { MapInfo, MapRegion, UIMap } from '@game/models/ui-map';
import { environment } from '@env/environment';
import { ImagePreloaderService } from '@core/services/image-preloader.service';
import { filter, map, skipUntil, take, takeUntil, withLatestFrom } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { SoundNotificationService } from '@core/services/sound-notification.service';
import { ActivatedRoute } from '@angular/router';


@Component({
    selector: 'app-game-area',
    templateUrl: './game-area.component.html',
    styleUrls: ['./game-area.component.scss']
})
export class GameAreaComponent implements OnInit, OnDestroy {

    gameId: number;

    debugMode = !environment.production;
    selectedTab: Tabs;
    showRegionNames = true;
    tabs = Tabs;

    status$: Observable<GameStatus>;
    isPlayerAwol$: Observable<boolean>;

    // head
    turnTimeLeft$: Observable<number>;
    roundNumber$: Observable<number>;
    players$: Observable<GamePlayer[]>;
    currentPlayer$: Observable<GamePlayer>;
    statusMessage$: Observable<string>;
    buttonAction$: Observable<ActionButtonState>;

    // map
    mapState$: Observable<MapState>;
    mapInfo$: Observable<MapInfo>;
    regionsCanvasObjects$: Observable<any>; // TODO: typing
    titlesCanvasObjects$: Observable<any>; // TODO: typing
    containersCanvasObjects$: Observable<any>; // TODO: typing
    mapRepresentation$: Observable<UIMap>;
    troopsDue$: Observable<number>;
    fromRegion$: Observable<MapRegion>;
    toRegion$: Observable<MapRegion>;

    // intel
    nextReserveCount$: Observable<number>;
    playerReserves$: Observable<Reserve[]>;
    playersIntel$: Observable<GamePlayerIntel[]>;

    // chatter
    chatMessages$: Observable<any[]>;
    unreadMessages$: Observable<any[]>;
    totalUnreadMessages$: Observable<number>;
    chatEnabled$: Observable<boolean>;

    // record
    record$: Observable<any[]>;

    // popups
    debriefPopupVisible$: Observable<boolean>;
    reserveCallUpPopupVisible$: Observable<boolean>;
    deployPopupVisbible$: Observable<boolean>;
    assaultPopupVisible$: Observable<boolean>;
    reinforcePopupVisible$: Observable<boolean>;
    victoryPopupVisible$: Subject<boolean>;
    pausedPopupVisible$: Observable<boolean>;
    cancelledPopupVisible$: Observable<boolean>;
    teamSelectionPopupVisible$ = new Subject<boolean>();

    // loading
    loadingProgress$: Observable<number | null>;
    gameOptions$: Observable<GameOptions>;

    attacks$: Observable<AttackInfo[]>;
    isReserveCallUpMandatory$: Observable<boolean>;
    isDeferredDeployment$: Observable<boolean>;
    containers$: Observable<any>;

    targetPlayer$: Observable<number>;
    selfPlayer$: Observable<GamePlayer>;
    lastDefeatedPlayer$: Observable<GamePlayer>;
    turnsTaken$: Observable<number>;
    timeTaken$: Observable<number>;
    points$: Observable<any>;

    teams$: Observable<GameTeam[]>;
    teamSize$: Observable<number>;

    cancelledMessage$: Observable<string>;

    private joinGameClick$ = new Subject();
    private onDestroy$ = new Subject<boolean>();

    constructor(private gameFacadeService: GameFacadeService,
                private imagePreloaderService: ImagePreloaderService,
                private toastr: ToastrService,
                private soundNotification: SoundNotificationService,
                private route: ActivatedRoute) { }

    ngOnInit() {

        this.gameId = this.route.snapshot.params['id'];

        // load the game
       this.gameFacadeService.loadGame(this.gameId);
        
        // open intel tab
        this.selectTab(Tabs.Intel);

        this.status$ = this.gameFacadeService.getStatus();
        this.isPlayerAwol$ = this.gameFacadeService.isPlayerAwol();

        // turn time left
        this.turnTimeLeft$ = this.gameFacadeService.getTurnRemainingTime();

        // current round number
        this.roundNumber$ = this.gameFacadeService.getRoundNumber();

        // list of players
        this.players$ = this.gameFacadeService.getPlayers();

        // get current player
        this.currentPlayer$ = this.gameFacadeService.getCurrentPlayer();

        // listen for status message
        this.statusMessage$ = this.gameFacadeService.getStatusMessage();

        // action button
        this.buttonAction$ = this.gameFacadeService.getActionButtonState();

        // next reserve call up troops
        this.nextReserveCount$ = this.gameFacadeService.getNextReserveCount();

        // player reserves
        this.playerReserves$ = this.gameFacadeService.getPlayerReserves();

        // players intel
        this.playersIntel$ = this.gameFacadeService.getPlayersIntel();

        // chat messages
        this.chatMessages$ = this.gameFacadeService.getChatMessages();
        this.unreadMessages$ = this.gameFacadeService.getUnreadMessages();
        this.totalUnreadMessages$ = this.gameFacadeService.getTotalUnreadMessages();
        this.chatEnabled$ = this.gameFacadeService.isChatEnabled();

        // record
        this.record$ = this.gameFacadeService.getRecord();

        // popup visible
        this.debriefPopupVisible$ = this.gameFacadeService.isDebriefPopupVisible();
        this.reserveCallUpPopupVisible$ = this.gameFacadeService.isReserveCallUpPopupVisible();
        this.deployPopupVisbible$ = this.gameFacadeService.isDeployPopupVisible();
        this.assaultPopupVisible$ = this.gameFacadeService.isAssaultPopupVisible();
        this.reinforcePopupVisible$ = this.gameFacadeService.isReinforcePopupVisible();

        this.victoryPopupVisible$ = new Subject();
        this.gameFacadeService.isVictoryPopupVisible().pipe(
            takeUntil(this.onDestroy$)
        ).subscribe(this.victoryPopupVisible$);

        this.pausedPopupVisible$ = this.gameFacadeService.isPausedPopupVisible();
        this.cancelledPopupVisible$ = this.gameFacadeService.isCancelledPopupVisible();

        // map state
        this.mapState$ = this.gameFacadeService.getMapState();
        
        this.mapInfo$ = this.gameFacadeService.getMapInfo();
        
        this.regionsCanvasObjects$ = this.gameFacadeService.getRegionsCanvasObjects();
        this.titlesCanvasObjects$ = this.gameFacadeService.getTitlesCanvasObjects();
        this.containersCanvasObjects$ = this.gameFacadeService.getContainersCanvasObjects();
        this.mapRepresentation$ = this.gameFacadeService.getMapRepresentation();

        this.fromRegion$ = this.gameFacadeService.getFromRegion();
        this.toRegion$ = this.gameFacadeService.getToRegion();
        this.troopsDue$ = this.gameFacadeService.getTroopsDue();

        // loading
        this.loadingProgress$ = this.gameFacadeService.getLoadingProgress();

        // game options
        this.gameOptions$ = this.gameFacadeService.getGameOptions();

        this.attacks$ = this.gameFacadeService.getAttackResults();
        this.isReserveCallUpMandatory$ = this.gameFacadeService.isReserveCallUpMandatory();
        this.isDeferredDeployment$ = this.gameFacadeService.isDeferredDeployment();
        this.containers$ = this.gameFacadeService.getContainersImages();

        this.targetPlayer$ = this.gameFacadeService.getTargetPlayer();
        this.selfPlayer$ = this.gameFacadeService.getSelfPlayer();
        this.lastDefeatedPlayer$ = this.gameFacadeService.getLastDefeatedPlayer();
        this.turnsTaken$ = this.gameFacadeService.getTurnsTaken();
        this.timeTaken$ = this.gameFacadeService.getTimeTaken();
        this.points$ = this.gameFacadeService.getPointsAwarded();

        this.teams$ = this.gameFacadeService.getTeams();
        this.teamSize$ = this.gameFacadeService.getTeamSize();

        this.cancelledMessage$ = this.gameFacadeService.getCancelledMessage();

        // team selection
        this.joinGameClick$.pipe(
            withLatestFrom(this.teamSize$),
            takeUntil(this.onDestroy$)
        ).subscribe(([_, teamSize]) => {
            if (teamSize === 1) {
                // singles teams, just join the game
                this.gameFacadeService.joinGame();
            } else {
                // display the team selection popup
                this.teamSelectionPopupVisible$.next(true);
            }
        });

        this.loadingProgress$.pipe(
            filter(val => val === 100),
            take(1) // take 1 and complete
        ).subscribe(() => {
            // the game is now loaded, we can preload the remaining images

            // pre load images
            const images = [
                '/assets/images/DeploymentBg.png',
                '/assets/images/AssaultBg.png',
                '/assets/images/ReservesBg.png',
                '/assets/images/Dice_Ice.png',
                '/assets/images/Dice_Fire.png'
            ];
            this.imagePreloaderService.preload(images);
        });

        // toastr for turn notification
        this.buttonAction$.pipe(
            filter(action => action === ActionButtonState.BeginTurn),
            takeUntil(this.onDestroy$)
        ).subscribe(() => {
            // this.toastr.info('It\'s your turn');
            this.soundNotification.playTurnNotificationAlert();
        });

        // toastr for new message
        this.unreadMessages$.pipe(
            skipUntil(timer(2000)), // only start listen after 2 seconds
            filter(messages => messages.length > 0),
            map(messages => messages[0]),
            withLatestFrom(this.players$),
            takeUntil(this.onDestroy$)
        ).subscribe(([message, players]) => {
            const playerIdx = players.findIndex(player => player.id === message.from);
            const playerName = playerIdx > -1 ? players[playerIdx].name + ': ' : '';

            // const toastr = this.toastr.success(playerName + message.text, 'New Message');
            this.soundNotification.playMessageNotificationAlert();

            // // when user click the message open the chatter
            // toastr.onTap.subscribe(() => {
            //     this.selectTab(Tabs.Chatter);
            // });
        });
    }

    selectFromRegion(regionId: string) {
        this.gameFacadeService.setFromRegion(regionId);
    }

    selectToRegion(regionId: string) {
        this.gameFacadeService.setToRegion(regionId);
    }


    /**
     * Actions
     */

    beginTurn() {
        this.gameFacadeService.beginTurn();
    }

    deployTroops(region: string, troops: number) {
        this.gameFacadeService.deployTroops(region, troops);
        this.closeDeployPopup();
    }

    attack(from: string, to: string) {
        this.gameFacadeService.attack(from, to);
    }

    blitz(from: string, to: string) {
        this.gameFacadeService.blitz(from, to);
    }

    advanceTroops(count: number) {
        this.gameFacadeService.advanceTroops(count);
        this.closeAssaultPopup();
    }

    endAssault() {
        this.gameFacadeService.endAssault();
    }

    reinforceRegion(from: string, to: string, troops: number) {
        this.gameFacadeService.reinforceRegion(from, to, troops);
        this.closeReinforcePopup();
    }

    endReinforcement() {
        this.gameFacadeService.endReinforcement();
    }

    skipDeployment() {
        this.gameFacadeService.skipDeployment();
    }

    callUpReserves(reserves: Reserve[]) {
        this.gameFacadeService.callUpReserves(reserves);
    }

    skipReserveCallUp() {
        this.gameFacadeService.skipReserveCallUp();
    }

    joinGame() {
        this.joinGameClick$.next(true);
    }

    joinTeam(id: number) {
        // hide selection popup
        this.teamSelectionPopupVisible$.next(false);
        this.gameFacadeService.joinTeam(id);
    }

    leaveGame() {
        this.gameFacadeService.leaveGame();
    }

    updatePlayerColor(playerId: number, colorId: string) {
        this.gameFacadeService.updatePlayerColor(playerId, colorId);
    }

    returnToBase() {
        this.gameFacadeService.returnToBase();
    }


    /**
     * Popups
     */

    closeDebriefPopup() {
        this.gameFacadeService.closeDebriefPopup();
    }

    closeDeployPopup() {
        this.gameFacadeService.setFromRegion(null);
    }

    closeAssaultPopup() {
        this.gameFacadeService.setFromRegion(null);
        this.gameFacadeService.setToRegion(null);
        this.gameFacadeService.clearAttacks();
    }

    closeReinforcePopup() {
        this.gameFacadeService.setFromRegion(null);
        this.gameFacadeService.setToRegion(null);
    }

    dismissVictoryPopup() {
        this.victoryPopupVisible$.next(false);
    }


    /**
     * Tabs
     */

    selectTab(tab: Tabs) {
        this.selectedTab = tab;
        this.gameFacadeService.toggleChatter(this.selectedTab === Tabs.Chatter);
    }


    /**
     * Chatter
     */

    sendMessage(message: string, scope: 'self' | 'team' | 'game') {
        // console.log('game-area-chat', message, scope);
        this.gameFacadeService.sendChatMessage(message, scope);
    }


    /**
     * Key binding
     */

    @HostListener('window:keyup', ['$event'])
    keyEvent(event: KeyboardEvent) {

        const path = (event as any).path || (event.composedPath && event.composedPath());

        if (path[0].nodeName === 'INPUT' || path[0].nodeName === 'TEXTAREA') {
            // we shouldn't do anything if key strokes are coming from an input fieled
            return;
        }

        switch (event.key) {
            case 'i':
            case 'I':
                this.selectTab(Tabs.Intel);
                break;

            case 'c':
            case 'C':
                this.selectTab(Tabs.Chatter);
                break;

            case 'r':
            case 'R':
                this.selectTab(Tabs.Record);
                break;

            case 'b':
            case 'B':
                this.selectTab(Tabs.Brief);
                break;

            case 'n':
            case 'N':
                this.showRegionNames = !this.showRegionNames;
                break;

            case 'Escape':
                // TODO: unselect regions if any
                break;
        }
    }

    ngOnDestroy(): void {
        this.onDestroy$.next(true);
        this.onDestroy$.complete();
    }
}

enum Tabs {
    Intel   = 'intel',
    Chatter = 'chatter',
    Record  = 'record',
    Brief   = 'brief',
    Debug   = 'debug'
}
