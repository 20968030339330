import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class ImagePreloaderService {

    constructor() { }

    preload(imagesUrl) {
        imagesUrl.map(imageUrl => {
            const img = new Image();
            img.src = imageUrl;
        });
    }
}
