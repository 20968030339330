import { Component, OnInit } from '@angular/core';
import { environment } from '@env/environment';
import { Router } from '@angular/router';

@Component({
  selector: 'app-nav-menu',
  templateUrl: './nav-menu.component.html',
  styleUrls: ['./nav-menu.component.scss']
})
export class NavMenuComponent implements OnInit {
  apiUrl: string;

  constructor(
    public router: Router
  ) { }

  ngOnInit() {
    this.apiUrl = environment.apiBaseUrl
  }

  isActiveRoute(route: string): boolean {
    return this.router.isActive(route, false);
  }
}
