/**
 * Actions are triggered by the player
 */
import { Reserve } from '@game/store/models/game.model';

export class InitGameAction {
    static type = '[Game] Init Game';
    constructor(public player: number) {}
}

export class GetCurrentGameAction {
    static type = '[Game] > Get Current Game';
}

export class GetGamePreferencesAction {
    static type = '[Game] > Get Preferences';
}

export class GetGameRecordAction {
    static type = '[Game] > Get Game Record';
}

export class JoinAction {
    static type = '[Game] > Join Game';
}

export class JoinTeamAction {
    static type = '[Game] > Join Team';
    constructor(public team: number) {}
}

export class LeaveAction {
    static type = '[Game] > Leave Game';
}

export class BeginTurnAction {
    static type = '[Game] > Begin Turn';
}

export class DeployTroopsAction {
    static type = '[Game] > Deploy Troops';
    constructor(public region: string, public count: number) {}
}

export class SkipDeploymentAction {
    static type = '[Game] > Skip Deployment';
}

export class AttackAction {
    static type = '[Game] > Attack';
    constructor(public fromRegion: string, public toRegion: string) {}
}

export class BlitzAction {
    static type = '[Game] > Blitz';
    constructor(public fromRegion: string, public toRegion: string) {}
}

export class AdvanceTroopsAction {
    static type = '[Game] > Advance Troops';
    constructor(public count: number) {}
}

export class EndAssaultAction {
    static type = '[Game] > End Assault';
}

export class ReinforceRegionAction {
    static type = '[Game] > Reinforce Region';
    constructor(public fromRegion: string, public toRegion: string, public troops: number) {}
}

export class EndReinforcementAction {
    static type = '[Game] > End Reinforcement';
}

export class ReturnToBaseAction {
    static type = '[Game] > Return to Base';
}

export class CallUpReservesAction {
    static type = '[Game] > Call Up Reserves';
    constructor(public reserves: Reserve[]) {}
}

export class SkipReserveCallUpAction {
    static type = '[Game] > Skip Call Up';
}

export class SetFromRegionAction {
    static type = '[Game] Set From Region';
    constructor(public region: string) {}
}

export class SetToRegionAction {
    static type = '[Game] Set To Region';
    constructor(public region: string) {}
}

export class GetReinforceableRegionsAction {
    static type = '[Game] > Get Reinforceable Regions';
    constructor(public region?: string) {}
}

export class ClearAttacksAction {
    static type = '[Game] > Clear Attacks';
    constructor() {}
}


/* others */

export class GetChatMessagesAction {
    static type = '[Game] > Get Chat Messages';
}

export class SendChatMessageAction {
    static type = '[Game] > Send Chat Message';
    constructor(public message: string, public scope: string) {}
}

export class SetPlayerColorAction {
    static type = '[Game] > Set Player Colors';
    constructor(public player: number, public color: string) {}
}

export class SetPlayerLastReadChatAction {
    static type = '[Game] > Set Player Last Read Chat';
    constructor(public timestamp: number) {}
}

export class GetActiveGames {
    static type = '[Game] > Get Active Games';
}
