import { Component, OnInit } from '@angular/core';
import { environment } from '@env/environment';
import { GameService } from '@gamelist/services/api/game.service';
import { Router } from '@angular/router';
import { SharedService } from 'src/app/services/shared.service';
import { BehaviorSubject, Observable } from 'rxjs';

@Component({
    selector: 'app-dashboard',
    templateUrl: './dashboard.component.html',
    styleUrls: ['./dashboard.component.scss']
})

/*interface DispatchData {
    title?: string;
    image_url?: string;
    click_url?: string;
}*/

export class DashboardComponent implements OnInit {

    selectedGameType = null;
    dogtagData:any = {};
    dogtagData$;
    dispatchData:any = {};
    dispatchData$;
    activeGames$;
    awaitingGames$;
    archivedGames$;
    selectedTab = 'active';
    subscription_level: number = 0;

    activeGamesRealTime$: any[] = [];
    active: boolean = true; 

    constructor(
        private gameService: GameService,
        private sharedService: SharedService,
        private router: Router
    ) {  }

    ngOnInit() {
        
        // this.gameService.connectWebsocket();
        let isLoggedInData = this.sharedService.checkLoggedinUserData();
        
        this.subscription_level = isLoggedInData.subscription_level;

        this.activeGames$ = this.gameService.getActivePlayerGames();
        this.awaitingGames$ = this.gameService.getAwaitingPlayerGames();
        this.archivedGames$ = this.gameService.getArchivedPlayerGames();

        this.dispatchData$ = this.gameService.getDispatchData();
        this.dogtagData$ = this.gameService.getDogTagData();
        this.dispatchData$.subscribe(val => {
            this.dispatchData = val;
        });
        this.dogtagData$.subscribe(val => {
            this.dogtagData = val;
        });
// console.log('active-games$', this.activeGames$);
        // this.sharedService.updateList(this.activeGames$);
        // this.sharedService.list$.subscribe((newList) => {
        //     this.activeGamesRealTime$ = newList;
        //     console.log('active-games', newList);
        // });
    }

    selectGameType(gameType: string) {
        this.selectedGameType = gameType;
    }

    startGame(type: string) {
        if (type == 'realtime' && this.subscription_level != 2)
        {
            this.router.navigate(['/beta/search'], { queryParams: { type: type, available_slots:1, 'subscription':'no' } });
        } else {
            this.router.navigate(['/beta/new'], { queryParams: { type: type } });
        }
    }
    
    joinGame(type) {
        this.router.navigate(['/beta/search'], { queryParams: { type: type, available_slots:1 } });
    }

    searchGame() {
        
        this.router.navigate(['/beta/search'], { queryParams: { showJoinedGames: true } });
    }

    selectTab(tab: string) {
        this.selectedTab = tab;
    }
}
